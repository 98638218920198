/* ----------------------------------

Template Name: Ambrox - Personal Portfolio React Template
Author: validtheme
Description:
Version: 1.0

Main Font    : Manrope
Main Color   : #5138ee

-------------------------------------

Table of contents
        
    01. Template default css
        - Animations
        - General
        - Section Title
        - Video Button
        - Typography
        - Tables
        - Forms
        - Buttons
        - Pagination
        - Colors
        - Accordion
        - Tabs
    02. Preloader
    03 Header
        - Header Left
        - Header Sidebar
        - Header Mobile
    04. Banner
        - Banner with typed text
    05. Services
        - Services Grid
        - Services Single (Popup)
	06. Projects / Portfolio / Gallery
        - Project Heading (Including Fun Factor)
        - Grid Colum
        - Project Single (Popup)
    07. About
    08. CV / Resume / Personal Information
        - Biography Tab
        - Skills Tab
        - Education Tab
	09. Pricing Table
        - Pricing Offer Banner
        - Pricing Item (Grid)
    10. Testimonials 
    11. Available
    12. Blog
        - Blog Grid
        - Blog Single (Popup)
    13. Contact
    14. Footer
	15. Not Found

*/

@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');


/* --------- Varialbes --------- */

:root {
	--font-default: 'Manrope', sans-serif;
	--black: #000000;
	--dark: #040836;
	--dark-secondary: #202942;
	--white: #ffffff;
	--color-primary: #ff014f;
	--color-secondary: #00c0ff;
	--color-heading: #202942;
	--color-paragraph: #666666;
	--box-shadow-primary: 1px 4px 20px -2px rgba(0, 0, 0, 0.1);
	--box-shadow-secondary: 0 20px 50px rgba(0, 0, 0, 0.1);
	--box-shadow-regular: 0px 2px 12px 0px #e7e7e7;
	--box-shadow-extra: 0 5px 50px 0 rgba(0, 0, 0, 0.15);
	--bg-gray: #edf5ff;
	--bg-gradient: linear-gradient(45deg, var(--color-secondary) 0%, var(--color-primary) 70%);
}

/* ============================================================== 
     # Browser Reset
=================================================================== */

* {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

body,
html {
	height: 100%;
}

html {
	margin: 0;
	padding: 0;
	width: 100%;
	height: 100%;
	scroll-behavior: smooth;
}

html,
body,
div,
span,
img,
ol,
ul,
li,
a,
p,
h1,
h2,
h3,
h4,
h5,
h6,
option,
legend,
label,
table,
th,
td,
tr,
article,
aside,
caption,
figure,
footer,
header,
hgroup,
mark,
nav,
section,
time,
audio,
video {
	margin: 0;
	padding: 0;
	border: 0;
	outline: 0;
}

.wrapper {
	height: 100%;
}

img {
	border: none;
	outline: none;
	max-width: 100%;
}

label {
	display: inline-block;
	font-weight: normal;
	margin-bottom: 5px;
	max-width: 100%;
}

a {
	outline: none;
	text-decoration: none;
	color: var(--color-heading);
	cursor: pointer;
}

a img {
	border: none;
}

a:active {
	outline: none;
	text-decoration: none;
	color: var(--color-heading);
	opacity: 1;
}

a:focus {
	outline: none;
	text-decoration: none;
	color: var(--color-heading);
}

a:hover {
	outline: none;
	text-decoration: none;
	color: var(--color-primary);
	opacity: 1;
}

button {
	outline: medium none;
}

iframe {
	border: none;
}

hr {
	margin-top: 0;
	margin-bottom: 0;
	border: 0;
	border-top: 1px solid #eeeeee;
}

pre {
	display: block;
	margin: 0 0 30px;
	padding: 9.5px;
	word-wrap: break-word;
	word-break: break-all;
	color: #333333;
	border: 1px solid #ededed;
	border-radius: inherit;
	background-color: #f9f9f9;
	font-size: 13px;
	line-height: 1.42857143;
}

input {
	border: 1px solid #e7e7e7;
	border-radius: inherit;
	-webkit-box-shadow: inherit;
	box-shadow: inherit;
	min-height: 50px;
}

input:focus {
	outline: none;
	-webkit-box-shadow: inherit;
	box-shadow: inherit;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type=number] {
	-moz-appearance: textfield;
	appearance: textfield;
}

textarea:focus {
	outline: none;
	-webkit-box-shadow: inherit;
	box-shadow: inherit;
}

select:focus {
	outline: none;
	-webkit-box-shadow: inherit;
	box-shadow: inherit;
}

ul {
	list-style-type: none;
}

ul,
li {
	margin: 0;
	padding: 0;
	list-style: none;
}

b {
	font-weight: 900;
}

strong {
	font-weight: 900;
}

.row {
	--bs-gutter-x: 30px;
}


/* ============================================================== 
	 01. Template default css
=================================================================== */

body {
	font-family: var(--font-default);
	font-size: 15px;
	font-weight: 500;
	line-height: 28px;
	overflow-x: hidden;
	background-color: var(--white);
	color: var(--color-paragraph);
}

h1,
h2,
h3,
h4,
h5,
h6 {
	color: var(--color-heading);
	font-weight: normal;
	line-height: 1.2;
	letter-spacing: 0;
	margin: 0;
	margin-bottom: 15px;
	font-weight: 700;
}

h1 {
	font-size: 40px;
}

h2 {
	font-size: 36px;
}

h3 {
	font-size: 27px;
}

h4 {
	font-size: 20px;
}

h5 {
	font-size: 18px;
}

h6 {
	font-size: 14px;
}

a,
.btn,
button {
	-webkit-transition: all 0.35s ease-in-out;
	transition: all 0.35s ease-in-out;
	outline: medium none;
	text-decoration: none;
	font-weight: 800;
}

p {
	color: var(--color-paragraph);
	margin: 0 0 15px;
	text-transform: none;
	font-weight: 500;
	font-family: var(--font-default);
}

body {
	background-image: url('../img/shape/banner-1.jpg');
	background-attachment: fixed !important;
	background-position: center center !important;
	background-size: cover !important;
}


/* ------------- Helper CSS ------------- */

@media (min-width: 1200px) {

	.container,
	.container-lg,
	.container-md,
	.container-sm,
	.container-xl,
	.container-xxl {
		max-width: 1200px;
	}
}

.container-full {
	padding: 0 15px;
	margin: auto;
	max-width: 1400px;
}

@media (min-width: 576px) {
	.container-full {
		max-width: 540px;
		width: 100%;
	}
}

@media (min-width: 768px) {
	.container-full {
		max-width: 720px;
		width: 100%;
	}
}

@media (min-width: 992px) {
	.container-full {
		max-width: 960px;
		width: 100%;
	}
}

@media (min-width: 1200px) {
	.container-full {
		max-width: 95%;
		width: 95%;
	}
}

.container-fill {
	padding: 0 15px;
	margin: auto;
	max-width: 100%;
}

@media (min-width: 576px) {
	.container-fill {
		max-width: 540px;
		width: 100%;
	}
}

@media (min-width: 768px) {
	.container-fill {
		max-width: 720px;
		width: 100%;
	}
}

@media (min-width: 992px) {
	.container-fill {
		max-width: 960px;
		width: 100%;
	}
}

@media (min-width: 1200px) {
	.container-fill {
		width: 100%;
		max-width: 100%;
	}
}

@media (max-width: 1199px) {
	.container-fill {
		padding: 0 15px;
		width: 100%;
	}
}

@media only screen and (min-width: 1367px) {
	.container-stage {
		margin-left: calc((100% - 1320px)/ 2);
		width: auto;
		min-width: auto;
		max-width: inherit;
		padding-right: 0;
	}
}

@media only screen and (max-width: 767px) {
	.row {
		--bs-gutter-x: 1.5rem;
	}
}

.bg-cover {
	background-position: center center !important;
	background-size: cover !important;
}

.bg-fixed {
	background-attachment: fixed !important;
	background-position: center center !important;
	background-size: cover !important;
}

.bg-fit {
	background-size: 100% 100% !important;
	background-position: center !important;
}

.bg-gray {
	background: var(--bg-gray);
}

.bg-gray-mixed {
	background: rgba(0, 0, 0, 0) linear-gradient(to bottom, var(--white) 0%, var(--bg-gray) 100%) repeat scroll 0 0;
}

.bg-light {
	background-color: var(--white);
}

.bg-dark {
	background-color: var(--dark) !important;
}

.bg-theme {
	background-color: var(--color-primary);
}

.bg-gradient {
	background-color: var(--color-primary);
	background: var(--bg-gradient) !important;
	background-size: 220% 150% !important;
}

.text-light {
	color: var(--white);
}

.text-light h1,
.text-light h2,
.text-light h3,
.text-light h4,
.text-light h5,
.text-light h6,
.text-light p,
.text-light span,
.text-light a {
	color: var(--white);
}

.shadow {
	-webkit-box-shadow: inherit !important;
	box-shadow: inherit !important;
}

.shadow.dark {
	position: relative;
	z-index: 1;
}

.shadow.dark:after {
	background: var(--dark);
	content: "";
	height: 100%;
	left: 0;
	opacity: 0.5;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: -1;
	-webkit-transition: all 0.35s ease-in-out;
	transition: all 0.35s ease-in-out;
}

.shadow.dark-hard {
	position: relative;
	z-index: 1;
}

.shadow.dark-hard:after {
	background: rgba(0, 4, 30, 0) none repeat scroll 0 0;
	content: "";
	height: 100%;
	left: 0;
	opacity: 0.65;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: -1;
	-webkit-transition: all 0.35s ease-in-out;
	transition: all 0.35s ease-in-out;
}

.shadow.theme {
	position: relative;
	z-index: 1;
}

.shadow.theme:after {
	background: var(--color-primary) none repeat scroll 0 0;
	content: "";
	height: 100%;
	left: 0;
	opacity: 0.7;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: -1;
	-webkit-transition: all 0.35s ease-in-out;
	transition: all 0.35s ease-in-out;
}

.shadow.light {
	position: relative;
	z-index: 1;
}

.shadow.light:after {
	background: var(--white) none repeat scroll 0 0;
	content: "";
	height: 100%;
	left: 0;
	opacity: 0.6;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: -1;
	-webkit-transition: all 0.35s ease-in-out;
	transition: all 0.35s ease-in-out;
}

.default-padding,
.default-padding-top,
.default-padding-bottom,
.default-padding-mx {
	position: relative;
}

.default-padding {
	padding-top: 120px;
	padding-bottom: 120px;
}

@media only screen and (max-width: 767px) {
	.default-padding {
		padding-top: 50px;
		padding-bottom: 50px;
	}
}

.default-padding-big {
	padding: 250px 0;
}

@media only screen and (max-width: 767px) {
	.default-padding-big {
		padding: 50px 0;
	}
}

.default-padding-top {
	padding-top: 120px;
}

@media only screen and (max-width: 767px) {
	.default-padding-top {
		padding-top: 50px;
	}
}

.default-padding-bottom {
	padding-bottom: 120px;
}

@media only screen and (max-width: 767px) {
	.default-padding-bottom {
		padding-bottom: 50px;
	}
}

.default-padding.bottom-less {
	padding-top: 120px;
	padding-bottom: 90px;
}

@media only screen and (max-width: 767px) {
	.default-padding.bottom-less {
		padding-top: 50px;
		padding-bottom: 20px;
	}
}

.default-padding-bottom.bottom-less {
	margin-bottom: -30px;
}

@media only screen and (max-width: 767px) {
	.default-padding-bottom.bottom-less {
		margin-bottom: 0;
	}
}

.default-padding-top.bottom-less {
	margin-bottom: -30px;
}

@media only screen and (max-width: 767px) {
	.default-padding-top.bottom-less {
		margin-bottom: -20px;
	}
}

@media only screen and (min-width: 1200px) {
	.align-center {
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
	}
}

.relative {
	position: relative;
}

.overflow-hidden {
	overflow: hidden;
}

.border-top {
	border-top: 1px solid #e7e7e7;
}

.border-bottom {
	border-bottom: 1px solid #e7e7e7;
}

.border-left {
	border-left: 1px solid #e7e7e7;
}

.border-right {
	border-right: 1px solid #e7e7e7;
}

.heading {
	font-weight: 800;
	margin-bottom: 25px;
	margin-top: -5px;
}

.heading span {
	font-weight: 500;
}

.site-heading {
	margin-bottom: 60px;
}

.site-heading .title {
	margin-bottom: 0;
}

.sub-title {
	text-transform: uppercase;
	color: var(--color-primary);
	font-weight: 500;
	background: var(--bg-gradient);
	-webkit-background-clip: text;
	background-clip: text;
	-webkit-text-fill-color: transparent;
	display: inline-block;
	position: relative;
	z-index: 1;
}

.bg-gradient .sub-title {
	color: var(--white);
	-webkit-background-clip: inherit;
	background-clip: inherit;
	-webkit-text-fill-color: inherit;
	background: transparent;
}

.site-heading p {
	margin-bottom: 0;
	margin-top: 25px;
	padding: 0 10%;
}

.site-heading .divider {
	display: inline-block;
	width: 50px;
	height: 2px;
	background: var(--color-primary);
	position: relative;
	z-index: 1;
	left: 10px;
}

.site-heading.light .divider,
.bg-theme .site-heading .divider,
.bg-gradient .site-heading .divider,
.shadow .site-heading .divider,
.bg-dark .site-heading .divider {
	background: var(--white);
}

.site-heading .divider:before {
	position: absolute;
	left: -15px;
	top: 0;
	content: "";
	height: 2px;
	width: 10px;
	background: var(--color-primary);
}

.site-heading.light .divider:before,
.bg-theme .site-heading .divider:before,
.bg-gradient .site-heading .divider:before,
.shadow .site-heading .divider:before,
.bg-dark .site-heading .divider:before {
	background: var(--white);
}

@media (max-width: 767px) {
	.site-heading p {
		padding: 0;
	}
}

@media only screen and (max-width: 767px) {
	.site-heading {
		margin-bottom: 30px;
	}
}

.heading-left {
	position: relative;
	z-index: 1;
	margin-bottom: 60px;
}

.heading-left .heading {
	font-weight: 800;
	margin-bottom: 0;
}

.heading-left .title {
	margin-bottom: 0;
}

.heading-shape {
	position: absolute;
	left: -45px;
	top: -30px;
	width: 50px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
	.heading-left .heading {
		margin-bottom: 30px;
	}
}

@media only screen and (max-width: 767px) {
	.heading-left .heading {
		margin-bottom: 15px;
	}
}

.heading-left p {
	margin-bottom: 0;
}

.heading-left .btn {
	margin-top: 30px;
}

@media only screen and (max-width: 767px) {
	.heading-left {
		margin-bottom: 40px;
	}
}

.bg-dark .heading-left p {
	opacity: 0.8;
}

.heading-left .content-left {
	border-right: 1px solid #e7e7e7;
	padding-right: 35px;
}

@media only screen and (max-width: 991px) {
	.heading-left .content-left {
		border: none;
		padding: 0;
		margin-bottom: 30px;
	}
}

.sub-heading {
	text-transform: uppercase;
	color: var(--color-primary);
	font-weight: 500;
	background: var(--bg-gradient);
	-webkit-background-clip: text;
	background-clip: text;
	-webkit-text-fill-color: transparent;
	background-size: 280% 150%;
	display: inline-block;
	position: relative;
	z-index: 1;
	margin-bottom: 20px;
}

.bg-gradient .sub-heading {
	color: var(--white);
	background: transparent;
	-webkit-background-clip: inherit;
	background-clip: inherit;
	-webkit-text-fill-color: inherit;
	opacity: 0.9;
}

.dark-layout h1,
.dark-layout h2,
.dark-layout h3,
.dark-layout h4,
.dark-layout h5,
.dark-layout h6 {
	color: var(--white);
}

.dark-layout {
	color: var(--white);
}

.dark-layout a {
	color: var(--white);
}

.dark-layout p {
	color: var(--white);
	opacity: 0.8;
}

/* === Video Play Button === */
.video-play-button {
	display: inline-block;
	background: var(--color-primary);
	height: 60px;
	width: 60px;
	line-height: 60px;
	text-align: center;
	border-radius: 50%;
	color: var(--color-heading);
	position: relative;
}

.video-play-button i {
	font-weight: 500;
	font-size: 20px;
	position: relative;
	left: 3px;
	top: 2px;
}

.video-play-button .effect {
	position: absolute;
	width: 100px;
	height: 100px;
	background: var(--color-primary);
	opacity: 0;
	border-radius: 100%;
	left: 50%;
	top: 50%;
	z-index: -1;
	-webkit-animation: video-play 1500ms ease-out infinite;
	animation: video-play 1500ms ease-out infinite;
}

.video-play-button .effect::after {
	position: absolute;
	content: "";
	width: 70px;
	height: 70px;
	background: var(--color-primary);
	opacity: 0;
	border-radius: 100%;
	left: 50%;
	top: 50%;
	z-index: -1;
	-webkit-animation: video-play 1500ms ease-out infinite;
	animation: video-play 1500ms ease-out infinite;
	-webkit-animation-delay: 2s;
	animation-delay: 2s;
}

.video-play-button .effect::before {
	position: absolute;
	content: "";
	width: 60px;
	height: 60px;
	background: var(--color-primary);
	opacity: 0;
	border-radius: 100%;
	left: 50%;
	top: 50%;
	z-index: -1;
	-webkit-animation: video-play 1500ms ease-out infinite;
	animation: video-play 1500ms ease-out infinite;
	-webkit-animation-delay: 3s;
	animation-delay: 3s;
}

.video-play-button.with-text {
	width: auto;
	background: transparent;
}

.video-play-button.with-text span {
	position: relative;
	z-index: 1;
}

.video-play-button.with-text span i {
	margin-left: 25px;
	margin-right: 30px;
	color: var(--white);
	left: -1px;
}

.video-play-button.with-text .effect {
	height: 60px;
	width: 60px;
	left: 0;
	-webkit-animation: inherit;
	animation: inherit;
	background: var(--color-primary);
	top: 0;
	opacity: 1;
	z-index: 1;
}

.video-play-button.with-text .effect::before {
	display: none;
}

.video-play-button.with-text .effect::after {
	background: var(--color-primary);
	height: 100%;
	width: 100%;
	z-index: inherit;
	-webkit-animation-delay: 0s;
	animation-delay: 0s;
}

.video-play-button.with-text.optional .effect {
	background: var(--white);
}

.video-play-button.with-text.optional .effect::after {
	background: var(--white);
}

.video-play-button.with-text.optional span i {
	color: var(--color-primary);
}

.video-play-button {
	color: var(--color-primary);
	font-size: 30px;
	left: 50%;
	padding-left: 7px;
	position: absolute;
	top: 50%;
	transform: translateX(-50%) translateY(-50%);
	-webkit-transform: translateX(-50%) translateY(-50%);
	-moz-transform: translateX(-50%) translateY(-50%);
	-ms-transform: translateX(-50%) translateY(-50%);
	-o-transform: translateX(-50%) translateY(-50%);
	z-index: 1;
}

.video-play-button:before {
	content: "";
	position: absolute;
	z-index: 0;
	left: 50%;
	top: 50%;
	-webkit-transform: translateX(-50%) translateY(-50%);
	transform: translateX(-50%) translateY(-50%);
	display: block;
	width: 80px;
	height: 80px;
	background: var(--white) repeat scroll 0 0;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%;
	-webkit-animation: pulse-border 1500ms ease-out infinite;
	animation: pulse-border 1500ms ease-out infinite;
}

.video-play-button.theme:before,
.video-play-button.theme:after {
	background: var(--color-primary) repeat scroll 0 0;
}

.orange .video-play-button.theme:before,
.orange .video-play-button.theme:after {
	background: #FF5621 repeat scroll 0 0;
}

.video-play-button:after {
	content: "";
	position: absolute;
	z-index: 1;
	left: 50%;
	top: 50%;
	-webkit-transform: translateX(-50%) translateY(-50%);
	transform: translateX(-50%) translateY(-50%);
	display: block;
	width: 80px;
	height: 80px;
	background: var(--white) repeat scroll 0 0;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%;
	transition: all 200ms;
	-webkit-transition: all 200ms;
	-moz-transition: all 200ms;
	-ms-transition: all 200ms;
	-o-transition: all 200ms;
}

@-webkit-keyframes pulse-border {
	0% {
		-webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
		transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
		opacity: 1;
	}

	100% {
		-webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
		transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
		opacity: 0;
	}
}

@keyframes pulse-border {
	0% {
		-webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
		transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
		opacity: 1;
	}

	100% {
		-webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
		transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
		opacity: 0;
	}
}

.video-play-button i {
	display: block;
	position: relative;
	z-index: 3;
	color: var(--color-primary);
}

.video-play-button.theme i {
	color: var(--white);
}

.video-play-button.relative {
	position: relative;
	display: inline-block;
	left: 30px;
	height: 68px;
	width: 68px;
	line-height: 68px;
	text-align: center;
	margin-top: 0 !important;
	top: 55px;
	margin-left: 10px;
}

.video-play-button.relative::before,
.video-play-button.relative::after {
	height: 68px;
	width: 68px;
	line-height: 68px;
}

.video-play-button.relative i {
	line-height: 70px;
	font-size: 25px;
}


/* ------------- Template Animation ------------- */

/* === Video Paly === */
@-webkit-keyframes video-play {
	0% {
		-webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
		transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
		opacity: 1;
	}

	100% {
		-webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
		transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
		opacity: 0;
	}
}

@keyframes video-play {
	0% {
		-webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
		transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
		opacity: 1;
	}

	100% {
		-webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
		transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
		opacity: 0;
	}
}

/* --------- Zoom Animation --------- */

.zoom-animation {
	-webkit-animation: zoom 2000ms ease-out infinite;
	animation: zoom 2000ms ease-out infinite;
}

/* --------- Slider Zoom Effect --------- */

@-webkit-keyframes zoom {
	from {
		-webkit-transform: scale(1, 1);
	}

	to {
		-webkit-transform: scale(1.2, 1.2);
	}
}

@keyframes zoom {
	from {
		-webkit-transform: scale(1, 1);
		transform: scale(1, 1);
	}

	to {
		-webkit-transform: scale(1.2, 1.2);
		transform: scale(1.2, 1.2);
	}
}

/* --------- Spinner Animation --------- */

.Spinner-animation {
	-webkit-animation: spinner 20s infinite linear;
	animation: spinner 20s infinite linear;
}

/* --------- Preloader Spinner Animation --------- */

@-webkit-keyframes spinner {
	to {
		-webkit-transform: rotateZ(360deg);
		transform: rotateZ(360deg);
	}
}

@keyframes spinner {
	to {
		-webkit-transform: rotateZ(360deg);
		transform: rotateZ(360deg);
	}
}


/* --------- Spinner Animation --------- */

.Spinner-reverse-animation {
	-webkit-animation: spinnerReverse 20s infinite linear;
	animation: spinnerReverse 20s infinite linear;
}

/* --------- Preloader Spinner Animation --------- */

@-webkit-keyframes spinnerReverse {
	to {
		-webkit-transform: rotateZ(-360deg);
		transform: rotateZ(-360deg);
	}
}

@keyframes spinnerReverse {
	to {
		-webkit-transform: rotateZ(-360deg);
		transform: rotateZ(-360deg);
	}
}

/* --------- Infinite Up Down Animation --------- */

@keyframes UpDown {
	0% {
		-webkit-transform: translateY(0px);
		transform: translateY(0px);
	}

	50% {
		-webkit-transform: translateY(-10px);
		transform: translateY(-10px);
	}

	100% {
		-webkit-transform: translateY(0px);
		transform: translateY(0px);
	}
}

/* --------- Animation UpDown --------- */

.updown-animation {
	-webkit-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
	-webkit-animation-timing-function: cubic-bezier(0.54, 0.085, 0.5, 0.92);
	animation-timing-function: cubic-bezier(0.54, 0.085, 0.5, 0.92);
	-webkit-animation-name: UpDown;
	animation-name: UpDown;
	-webkit-animation-duration: 2s;
	animation-duration: 2s;
}

@-webkit-keyframes UpDown {
	0% {
		-webkit-transform: translateY(0px);
		transform: translateY(0px);
	}

	50% {
		-webkit-transform: translateY(-25px);
		transform: translateY(-25px);
	}

	100% {
		-webkit-transform: translateY(0px);
		transform: translateY(0px);
	}
}

/* --------- Animation Left Right --------- */

.leftRight-animation {
	-webkit-animation: fadeLeftRight 10s ease-out infinite;
	animation: fadeLeftRight 10s ease-out infinite;
}

/* --------- Fade Left Right --------- */

@keyframes fadeLeftRight {

	0%,
	100% {
		-webkit-transform: translateX(0px);
		transform: translateX(0px);
	}

	50% {
		-webkit-transform: translateX(100px);
		transform: translateX(100px);
	}
}

@-webkit-keyframes fadeLeftRight {

	0%,
	100% {
		-webkit-transform: translateX(0px);
		transform: translateX(0px);
	}

	50% {
		-webkit-transform: translateX(100px);
		transform: translateX(100px);
	}
}

/* --------- Animation Right Left --------- */

.rightLeft-animation {
	-webkit-animation: fadeRightLeft 10s ease-out infinite;
	animation: fadeRightLeft 10s ease-out infinite;
}

@keyframes fadeRightLeft {

	0%,
	100% {
		-webkit-transform: translateX(0px);
		transform: translateX(0px);
	}

	50% {
		-webkit-transform: translateX(-50px);
		transform: translateX(-50px);
	}
}

@-webkit-keyframes fadeRightLeft {

	0%,
	100% {
		-webkit-transform: translateX(0px);
		transform: translateX(0px);
	}

	50% {
		-webkit-transform: translateX(-50px);
		transform: translateX(-50px);
	}
}

/* --------- Animation Zoom Up Down --------- */

.zoomUpDown-animation {
	-webkit-animation: zoomUpDown 10s ease-out infinite;
	animation: zoomUpDown 10s ease-out infinite;
}

/* --------- Zoom Up Down --------- */

@keyframes zoomUpDown {

	0%,
	100% {
		-webkit-transform: scale(0.5);
		transform: scale(0.5);
	}

	50% {
		-webkit-transform: scale(0.7);
		transform: scale(0.7);
	}
}

@-webkit-keyframes zoomUpDown {

	0%,
	100% {
		-webkit-transform: scale(0.5);
		transform: scale(0.5);
	}

	50% {
		-webkit-transform: scale(0.8);
		transform: scale(0.8);
	}
}

/* --------- Animation Moving --------- */

.moving-animation {
	-webkit-animation: moving 10s ease-out infinite;
	animation: moving 10s ease-out infinite;
}

/* --------- Moving Animation --------- */

@-webkit-keyframes moving {
	0% {
		-webkit-transform: translate(0px, 0px);
		transform: translate(0px, 0px);
	}

	20% {
		-webkit-transform: translate(0px, -30px);
		transform: translate(0px, -30px);
	}

	50% {
		-webkit-transform: translate(-100px, -30px);
		transform: translate(-100px, -30px);
	}

	70% {
		-webkit-transform: translate(-100px, 0px);
		transform: translate(-100px, 0px);
	}

	100% {
		-webkit-transform: translate(0px, 0px);
		transform: translate(0px, 0px);
	}
}

@keyframes moving {
	0% {
		-webkit-transform: translate(0px, 0px);
		transform: translate(0px, 0px);
	}

	20% {
		-webkit-transform: translate(0px, -30px);
		transform: translate(0px, -30px);
	}

	50% {
		-webkit-transform: translate(-100px, -30px);
		transform: translate(-100px, -30px);
	}

	70% {
		-webkit-transform: translate(-100px, 0px);
		transform: translate(-100px, 0px);
	}

	100% {
		-webkit-transform: translate(0px, 0px);
		transform: translate(0px, 0px);
	}
}

/* --------- Animation Rotation --------- */

@keyframes infinite-rotate {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	50% {
		-webkit-transform: rotate(180deg);
		transform: rotate(180deg);
	}

	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@-webkit-keyframes infinite-rotate {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	50% {
		-webkit-transform: rotate(180deg);
		transform: rotate(180deg);
	}

	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

/* --------- Button Border Animation --------- */

@-webkit-keyframes open {
	0% {
		width: 0;
		height: 0;
	}

	50% {
		width: 100px;
		height: 0;
	}

	100% {
		width: 100px;
		height: 55px;
	}
}

@keyframes open {
	0% {
		width: 0;
		height: 0;
	}

	50% {
		width: 100px;
		height: 0;
	}

	100% {
		width: 100px;
		height: 55px;
	}
}

@-webkit-keyframes openB {
	0% {
		width: 0px;
	}

	100% {
		width: 100px;
	}
}

@keyframes openB {
	0% {
		width: 0px;
	}

	100% {
		width: 100px;
	}
}

/* --------- Animation Shine --------- */

@-webkit-keyframes shine {
	0% {
		background-position: 0;
	}

	60% {
		background-position: 180px;
	}

	100% {
		background-position: 180px;
	}
}

@keyframes shine {
	0% {
		background-position: 0;
	}

	60% {
		background-position: 180px;
	}

	100% {
		background-position: 180px;
	}
}

/* --------- Pulse Animation --------- */

@keyframes pulsate {
	0% {
		-webkit-transform: scale(0.1, 0.1);
		opacity: 0.0;
	}

	50% {
		opacity: 1.0;
	}

	100% {
		-webkit-transform: scale(1.2, 1.2);
		opacity: 0.0;
	}
}

/* ------------- Button Styles ------------- */

.btn {
	display: inline-block;
	font-weight: 800;
	text-transform: capitalize;
	transition: all 0.35s ease-in-out;
	overflow: hidden;
	border-radius: 3px;
	border: 2px solid transparent;
	-webkit-box-shadow: inherit;
	box-shadow: inherit;
	font-size: 16px;
	letter-spacing: 0;
	padding: 13px 30px;
	background: #E7EDF8;
	position: relative;
	z-index: 1;
}

.btn:focus,
.btn.active {
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	-ms-border-radius: 5px;
	-o-border-radius: 5px;
	border-radius: 5px;
	-webkit-box-shadow: inherit;
	box-shadow: inherit;
	border: 2px solid transparent;
}

.btn:hover {
	background-color: var(--color-primary);
	color: var(--white);
}

.btn.radius {
	border-radius: 6px;
}

.btn.btn-border {
	border: 2px solid #e7e7e7;
	background: transparent;
}

.btn.btn-border:hover {
	background: var(--color-primary);
	border-color: var(--color-primary);
}

.btn.btn-border.animation::after {
	position: absolute;
	top: 0;
	right: inherit;
	bottom: inherit;
	left: 0;
	content: "";
	height: 100%;
	width: 0;
	z-index: -1;
	background: #e7e7e7;
	-webkit-transition: all 0.25s ease-in-out;
	transition: all 0.25s ease-in-out;
}

.btn.btn-border.animation:hover {
	border-color: #e7e7e7;
	color: var(--dark);
}

.btn.btn-border.animation:hover::after {
	width: 100%;
}

.btn.btn-border-dark {
	border: 2px solid var(--dark);
	background: transparent;
}

.btn.btn-border-dark:hover {
	background: var(--dark);
	border-color: var(--dark);
}

.btn.btn-border-dark.animation::after {
	position: absolute;
	top: 0;
	right: inherit;
	bottom: inherit;
	left: 0;
	content: "";
	height: 100%;
	width: 0;
	z-index: -1;
	background: var(--dark);
	-webkit-transition: all 0.25s ease-in-out;
	transition: all 0.25s ease-in-out;
}

.btn.btn-border-dark.animation:hover {
	border-color: var(--dark);
	color: var(--white);
}

.btn.btn-border-dark.animation:hover::after {
	width: 100%;
}

.btn.btn-border-theme {
	border: 2px solid var(--color-primary);
	background: transparent;
}

.btn.btn-border-theme:hover {
	background: var(--color-primary);
	border-color: var(--color-primary);
}

.btn.btn-border-theme.animation::after {
	position: absolute;
	top: 0;
	right: inherit;
	bottom: inherit;
	left: 0;
	content: "";
	height: 100%;
	width: 0;
	z-index: -1;
	background: var(--color-primary);
	-webkit-transition: all 0.25s ease-in-out;
	transition: all 0.25s ease-in-out;
}

.btn.btn-border-theme.animation:hover {
	border-color: var(--color-primary);
	color: var(--white);
}

.btn.btn-border-theme.animation:hover::after {
	width: 100%;
}

.btn.btn-border-light {
	border: 2px solid var(--white);
	background: transparent;
	color: var(--white);
}

.btn.btn-border-light:hover {
	background: var(--white);
	border-color: var(--white);
	color: var(--dark);
}

.btn.btn-border-light.animation::after {
	position: absolute;
	top: 0;
	right: inherit;
	bottom: inherit;
	left: 0;
	content: "";
	height: 100%;
	width: 0;
	z-index: -1;
	background: var(--white);
	-webkit-transition: all 0.25s ease-in-out;
	transition: all 0.25s ease-in-out;
}

.btn.btn-border-light.animation:hover {
	border-color: var(--white);
	color: var(--dark);
}

.btn.btn-border-light.animation:hover::after {
	width: 100%;
}

.btn.btn-gradient {
	border: none;
	color: var(--white);
}

.btn.btn-gradient::after {
	position: absolute;
	top: 0;
	right: inherit;
	bottom: inherit;
	left: 0;
	content: "";
	height: 100%;
	width: 100%;
	z-index: -1;
	background-image: linear-gradient(to right, #6222cc, #a200be, #6222cc);
	background-size: 220% 150%;
	-webkit-transition: all 0.25s ease-in-out;
	transition: all 0.25s ease-in-out;
}

.btn.btn-gradient:hover::after {
	background-position: -70% 0;
}

.btn.btn-gradient.active {
	background-position: -70% 0;
}

.btn.btn-theme {
	color: var(--white);
	border: none;
	background: var(--color-primary);
}

.btn.btn-theme::after {
	position: absolute;
	top: inherit;
	right: inherit;
	bottom: inherit;
	left: -5%;
	content: "";
	height: 150px;
	width: 115%;
	z-index: -1;
	background-color: var(--dark);
	-webkit-transition: all 0.25s ease-in-out;
	transition: all 0.25s ease-in-out;
	-webkit-transform: translateY(-45%) skew(25deg) scale(0);
	transform: translateY(-45%) skew(25deg) scale(0);
}

.btn.btn-theme:hover::after {
	-webkit-transform: translateY(-45%) skew(25deg) scale(1.2);
	transform: translateY(-45%) skew(25deg) scale(1.2);
}

.btn.btn-theme.secondary {
	color: var(--white);
	border: none;
	background: var(--color-optional);
}

.btn.btn-theme.secondary::after {
	position: absolute;
	top: inherit;
	right: inherit;
	bottom: inherit;
	left: -5%;
	content: "";
	height: 150px;
	width: 115%;
	z-index: -1;
	background-color: var(--dark);
	-webkit-transition: all 0.25s ease-in-out;
	transition: all 0.25s ease-in-out;
	-webkit-transform: translateY(-45%) skew(25deg) scale(0);
	transform: translateY(-45%) skew(25deg) scale(0);
}

.text-light .btn.btn-theme.secondary::after {
	background-color: var(--white);
}

.text-light .btn.btn-theme.secondary:hover {
	color: var(--color-heading);
}

.btn.btn-theme.secondary:hover::after {
	-webkit-transform: translateY(-45%) skew(25deg) scale(1.2);
	transform: translateY(-45%) skew(25deg) scale(1.2);
}

.btn.btn-theme.hover-light::after {
	background-color: var(--white);
	-webkit-transition: all 0.25s ease-in-out;
	transition: all 0.25s ease-in-out;
}

.btn.btn-theme.hover-light:hover {
	color: var(--dark);
	-webkit-transition: all 0.25s ease-in-out;
	transition: all 0.25s ease-in-out;
}

.btn.btn-theme.hover-light:hover::after {
	-webkit-transform: translateY(-45%) skew(25deg) scale(1.2);
	transform: translateY(-45%) skew(25deg) scale(1.2);
}

.btn.btn-light {
	color: var(--color-heading);
	border: none;
	background: var(--white);
	border: 2px solid transparent;
}

.btn.btn-light.btn-md {
	padding: 13px 52px;
}

.btn.btn-light:hover {
	color: var(--white);
	background: transparent;
	border: 2px solid var(--white);
}

.btn.btn-dark {
	color: var(--white);
	background: var(--dark);
	border: none;
}

.btn.btn-dark::after {
	position: absolute;
	top: 0;
	right: inherit;
	bottom: inherit;
	left: 0;
	content: "";
	height: 100%;
	width: 0;
	z-index: -1;
	background-color: var(--color-primary);
	-webkit-transition: all 0.25s ease-in-out;
	transition: all 0.25s ease-in-out;
}

.dark-layout .btn.btn-theme::after {
	background: var(--color-secondary);
}

.shadow .btn.btn-dark::after {
	background-color: var(--white);
}

.shadow .btn.btn-dark:hover {
	color: var(--color-heading) !important;
}

.btn.btn-dark:hover::after {
	width: 100%;
}

.btn.btn-dark.secondary {
	color: var(--white);
	border: none;
	background: var(--dark-optional);
}

.btn.btn-dark.secondary::after {
	position: absolute;
	top: inherit;
	right: inherit;
	bottom: inherit;
	left: -5%;
	content: "";
	height: 150px;
	width: 115%;
	z-index: -1;
	background-color: var(--white);
	-webkit-transition: all 0.25s ease-in-out;
	transition: all 0.25s ease-in-out;
	-webkit-transform: translateY(-45%) skew(25deg) scale(0);
	transform: translateY(-45%) skew(25deg) scale(0);
}

.btn.btn-dark.secondary:hover::after {
	-webkit-transform: translateY(-45%) skew(25deg) scale(1.2);
	transform: translateY(-45%) skew(25deg) scale(1.2);
}

.btn.btn-dark.secondary:hover {
	color: var(--color-heading);
}

.btn i {
	position: relative;
	top: 1px;
	margin-left: 5px;
}

.btn.text-slide {
	min-width: 150px;
	height: 55px;
	line-height: 55px;
}

.btn.text-slide span {
	position: absolute;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	text-align: center;
}

.btn.animate-border {
	overflow: inherit;
	z-index: inherit;
	width: 200px;
	height: 55px;
	line-height: 55px;
	padding: 0;
}

.btn.animate-border:hover {
	background: transparent;
	color: var(--dark);
}

.btn.animate-border:hover .hover-border::after,
.btn.animate-border:hover .hover-border::before {
	opacity: 1;
	-webkit-animation: open 0.4s;
	animation: open 0.4s;
	-webkit-animation-fill-mode: forwards;
	animation-fill-mode: forwards;
	-webkit-animation-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
	animation-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
	-webkit-animation-direction: normal;
	animation-direction: normal;
}

.btn.animate-border:hover .hover-border-bottom::after,
.btn.animate-border:hover .hover-border-bottom::before {
	opacity: 1;
	-webkit-animation: openB 0.4s;
	animation: openB 0.4s;
	-webkit-animation-delay: 0.4s;
	animation-delay: 0.4s;
	-webkit-animation-fill-mode: forwards;
	animation-fill-mode: forwards;
	-webkit-animation-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
	animation-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
	-webkit-animation-direction: normal;
	animation-direction: normal;
}

.btn.animate-border .hover-border {
	position: absolute;
	left: 0;
	top: -1px;
	height: 100%;
	width: 100%;
}

.btn.animate-border .hover-border::before {
	position: absolute;
	content: "";
	height: 0;
	width: 0;
	display: block;
	opacity: 0;
	border-top: solid 2px var(--color-primary);
	border-left: solid 2px var(--color-primary);
	right: 98px;
	top: -1px;
}

.btn.animate-border .hover-border::after {
	position: absolute;
	content: "";
	height: 0;
	width: 0;
	display: block;
	opacity: 0;
	border-top: solid 2px var(--color-primary);
	border-right: solid 2px var(--color-primary);
	left: 98px;
	top: -1px;
}

.btn.animate-border .hover-border-bottom {
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	z-index: 1;
}

.btn.animate-border .hover-border-bottom::before {
	position: absolute;
	content: "";
	width: 0;
	display: block;
	opacity: 0;
	height: 55px;
	border-bottom: solid 2px var(--color-primary);
	right: -1px;
	bottom: -2px;
}

.btn.animate-border .hover-border-bottom::after {
	position: absolute;
	content: "";
	width: 0;
	display: block;
	opacity: 0;
	height: 100%;
	border-bottom: solid 2px var(--color-primary);
	left: 0;
	bottom: -2px;
}

.btn.animated-arrow {
	border: none;
	background: transparent;
	min-width: 14rem;
	height: auto;
	padding: 0;
}

.btn.animated-arrow .circle {
	-webkit-transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
	transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
	position: relative;
	display: block;
	margin: 0;
	width: 3.5rem;
	height: 3.5rem;
	background: var(--color-primary);
	border-radius: 2rem;
}

.btn.animated-arrow .circle .icon {
	-webkit-transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
	transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
	left: 15px;
	width: 1.125rem;
	height: 0.125rem;
	background: none;
	position: absolute;
	top: 0;
	bottom: 0;
	margin: auto;
}

.btn.animated-arrow .circle .icon::before {
	position: absolute;
	content: "";
	top: -0.25rem;
	right: 0.0625rem;
	width: 0.625rem;
	height: 0.625rem;
	border-top: 0.125rem solid var(--white);
	border-right: 0.125rem solid var(--white);
	-webkit-transform: rotate(45deg);
	transform: rotate(45deg);
}

.btn.animated-arrow .button-text {
	-webkit-transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
	transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	padding: 15px 0;
	margin: 0 0 0 70px;
	color: var(--dark);
	line-height: 2;
	text-align: left;
	width: 100%;
	font-size: 14px;
}

.btn.animated-arrow:hover {
	color: var(--white);
}

.btn.animated-arrow:hover .circle {
	width: 100%;
}

.btn.animated-arrow:hover .circle .icon.arrow {
	background: var(--white);
	-webkit-transform: translate(1.5rem, 0);
	transform: translate(1.5rem, 0);
}

.btn.animated-arrow:hover .button-text {
	color: var(--white);
}

.btn.circle {
	border-radius: 30px !important;
}

.btn-simple {
	line-height: 1;
	text-transform: uppercase;
	display: inline-block;
	color: var(--color-paragraph);
}

.btn-simple i {
	transform: rotate(-45deg);
	display: inline-block;
	margin-left: 2px;
}

.btn-simple:hover {
	color: var(--color-primary);
}

.video-btn i {
	display: inline-block;
	height: 55px;
	width: 55px;
	text-align: center;
	line-height: 55px;
	background: var(--color-primary);
	border-radius: 50%;
	position: relative;
	margin-right: 20px;
	color: var(--white);
}

.text-light .video-btn i {
	background: var(--white);
	color: var(--color-primary);
}

.video-btn i::after {
	content: "";
	position: absolute;
	z-index: 0;
	left: 50%;
	top: 50%;
	-webkit-transform: translateX(-50%) translateY(-50%);
	transform: translateX(-50%) translateY(-50%);
	display: block;
	width: 60px;
	height: 60px;
	background: var(--color-primary) repeat scroll 0 0;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%;
	-webkit-animation: pulse-border 1500ms ease-out infinite;
	animation: pulse-border 1500ms ease-out infinite;
	z-index: -1;
}

.text-light .video-btn i::after {
	background: var(--white) repeat scroll 0 0;
}

.text-shine {
	background: linear-gradient(to right, var(--dark) 0, var(--white) 10%, var(--color-primary) 20%);
	background-position: 0;
	-webkit-background-clip: text;
	background-clip: text;
	-webkit-text-fill-color: transparent;
	-webkit-animation: shine 3s infinite linear;
	animation: shine 3s infinite linear;
	-webkit-animation-fill-mode: forwards;
	animation-fill-mode: forwards;
	-webkit-text-size-adjust: none;
	font-weight: 800;
	font-size: 16px;
	text-decoration: none;
	white-space: nowrap;
	padding: 12px 48px;
}

.animate-inout {
	background: linear-gradient(90deg, #8A6552 0%, #472d20 100%);
	color: var(--white);
	font-size: 12px;
	text-transform: capitalize;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	position: relative;
	overflow: hidden;
	border: solid 2px var(--color-primary);
	cursor: pointer;
}

.btn-icon {
	position: relative;
	padding-left: 20px;
}

.btn-icon:hover {
	color: var(--color-primary);
}

.btn-icon::after {
	position: absolute;
	right: 10px;
	top: 50%;
	content: "";
	height: 2px;
	left: 0;
	background: var(--color-heading);
	transform: translateY(-50%);
	margin-top: -1px;
	transition: all 0.35s ease-in-out;
}

.btn-icon:hover::after {
	background: var(--color-primary);
}

.btn-icon i {
	font-weight: 500;
}

.btn-icon:hover {
	padding-left: 40px;
}

.animate-inout span {
	z-index: 1;
	-webkit-transition: all 0.3s ease-out;
	transition: all 0.3s ease-out;
	line-height: 20px;
}

.animate-inout::before,
.animate-inout::after {
	width: 0%;
	height: 0%;
	position: absolute;
	content: "";
	border-radius: 100%;
}

.animate-inout:after {
	-webkit-transition: all 0.5s ease;
	transition: all 0.5s ease;
	background-color: var(--white);
}

.animate-inout:before {
	-webkit-transition: all 0.5s ease-out;
	transition: all 0.5s ease-out;
	background: var(--color-primary);
}

.animate-inout.animate-inout-border {
	background: transparent;
	color: var(--dark);
}

.animate-inout.animate-inout-border::after {
	background: linear-gradient(90deg, #8A6552 0%, #472d20 100%);
}

.animate-inout.animate-inout-border:hover {
	color: var(--white);
}

.animate-inout.animate-inout-border:hover::before {
	-webkit-transition: all 0.5s ease-in;
	transition: all 0.5s ease-in;
}

.animate-inout.animate-inout-border:hover::after {
	-webkit-transition: all 0.7s ease-in;
	transition: all 0.7s ease-in;
}

.animate-inout:hover {
	color: var(--dark);
}

.animate-inout:hover::before,
.animate-inout:hover::after {
	width: 200px;
	height: 200px;
	border-radius: 4px;
}

.animate-inout:hover::before {
	-webkit-transition: all 0.5s ease-in;
	transition: all 0.5s ease-in;
}

.animate-inout:hover::after {
	-webkit-transition: all 0.7s ease-in;
	transition: all 0.7s ease-in;
}

.btn-md {
	padding: 16px 52px;
	font-size: 17px;
}

.btn-sm {
	padding: 16px 50px;
	font-size: 14px;
}

/* ============================================================== 
     03 Header
=================================================================== */

header.header-fixed {
	position: fixed;
	left: 0;
	top: 0;
	bottom: 0;
	width: 110px;
	background: var(--white);
	z-index: 9;
	text-align: center;
	overflow: hidden;
}

@media (max-width: 1023px) {
	header.header-fixed {
		display: none;
	}
}

.header-fixed .f-flex {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;
}

@media (min-width: 1024px) {
	.mobile-nav {
		display: none !important;
	}

	body.bg-fixed {
		padding-left: 110px;
	}
}

header.header-fixed .logo a {
	display: block;
	padding: 20px;
	text-align: center;
}

header.header-fixed .logo img {
	height: auto;
	width: auto;
	max-height: 60px;
}

header.header-fixed .video {
	position: relative;
	background-size: cover;
	background-position: center top;
	padding-bottom: 30px;
}

header.header-fixed .video .video-play-button {
	position: relative;
	left: 0;
	bottom: 0;
	transform: inherit;
	padding: 0;
	top: auto;
	background: var(--color-primary);
}

header.header-fixed .video .video-play-button i {
	font-size: 35px;
	position: relative;
	left: 0;
}

header.header-fixed .menu ul li {
	display: block;
}

header.header-fixed .menu a {
	display: block;
	border-top: 1px solid #e7e7e7;
	padding: 15px;
	position: relative;
	background: transparent;
}

header.header-fixed .menu li:first-child a {
	border: none;
}

header.header-fixed .menu a i {
	font-weight: 100;
	font-size: 30px;
}

.menu-name {
	font-size: 14px;
}

header.header-fixed .menu a .menu-name {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	left: -30px;
	display: block;
	background: var(--white);
	padding: 19px 10px;
	font-weight: 800;
	width: 100%;
	height: 100%;
	text-align: center;
	transition: all 0.35s ease-in-out;
	opacity: 0;
	visibility: hidden;
	text-transform: uppercase;
	font-size: 13px;
}

header.header-fixed .menu a:hover .menu-name {
	left: 0;
	opacity: 1;
	visibility: visible;
}

header.header-fixed .menu a i {
	transition: all 0.35s ease-in-out;
	position: relative;
	left: 0;
}

header.header-fixed .menu a:hover i {
	left: 40px;
	opacity: 0;
	visibility: hidden;
}

header.header-fixed .video-play-button.theme:before,
header.header-fixed .video-play-button.theme:after {
	background: var(--color-secondary);
	height: 60px;
	width: 60px;
}

.attr-nav li.side-menu a {
	padding: 7px 20px !important;
	display: block;
}

.navbar.no-background.dark .attr-nav .side-menu span {
	background-color: var(--color-heading);
}

.dark-layout header.header-fixed {
	background: var(--dark);
}

.dark-layout header.header-fixed .menu a {
	border-color: rgba(255, 255, 255, 0.15);
}

.dark-layout header.header-fixed .menu a .menu-name {
	background: transparent;
	color: var(--white);
}

.dark-layout header.header-fixed .menu a i {
	color: var(--white);
}

/* ----- offCanvas Logo ----- */

.offcanvas-header img {
	max-height: 40px;
}

.offcanvas-close-btn {
	border: 2px solid #ddd;
	border-radius: 50%;
	color: #232323;
	width: 40px;
	height: 40px;
	background: none;
}

.offcanvas-close-btn .close-btn {
	position: relative;
	top: 1px;
	color: #232323;
}

.offcanvas-icon{
	color: #232323;
}

.offcanvas-body {
	padding: 20px 30px;
}

.offcanvas-body ul li a {
	display: block;
	color: #232323 !important;
	font-weight: 700;
	padding: 13px 0;
	border-bottom: 1px solid #e0e0e0;
}

.offcanvas-body ul li:last-child a {
	border-bottom: none;
}

.offcanvas-body ul li a.active {
	color: var(--color-primary) !important;
}

/* ============================================================== 
    # Breadcrumb Styles
=================================================================== */

.breadcrumb-area {
	padding: 150px 0;
	padding-top: 200px;
	position: relative;
	z-index: 1;
	overflow: hidden;
	background-color: #d1eeec;
	background-size: 30%, contain;
	background-repeat: no-repeat;
	background-position: top right;
}

.breadcrumb-area .top-pad-extra {
	padding-top: 240px;
}

.breadcrumb-area .row {
	position: relative;
	z-index: 1;
}

.breadcrumb-area .breadcrumb {
	background: transparent none repeat scroll 0 0;
	display: inline-block;
	margin: 0;
	padding: 0;
	position: relative;
	z-index: 1;
}

.breadcrumb-area .breadcrumb > li + li::before {
	content: "\f105";
	font-family: "Font Awesome 5 Pro";
	font-weight: 700;
	padding: 0 5px;
	color: var(--white);
	display: none;
}

.breadcrumb-area .breadcrumb li {
	padding: 0 10px;
	position: relative;
	display: inline-block;
	z-index: 1;
	font-weight: 800;
	color: var(--white);
	text-transform: uppercase;
	font-size: 14px;
}

.breadcrumb-area .breadcrumb li a {
	color: var(--white);
	font-weight: 800;
}

.breadcrumb-area .breadcrumb li a:hover {
	color: var(--color-primary);
}

.breadcrumb-area .breadcrumb li.active {
	color: var(--color-primary);
}

.breadcrumb-area .breadcrumb li::after {
	content: "\e649";
	font-family: 'themify';
	font-weight: 500;
	right: -5px;
	position: absolute;
	line-height: 0;
	top: 50%;
	margin-top: -1px;
	font-size: 11px;
}

.breadcrumb-area .breadcrumb li i {
	margin-right: 3px;
}

.breadcrumb-area .breadcrumb li:last-child::after {
	display: none;
}

.breadcrumb-area h1 {
	display: block;
	font-weight: 800;
	margin-top: -10px;
}

@media only screen and (max-width: 767px) {
	.breadcrumb-area {
		padding: 80px 0 !important;
	}
}

/* ============================================================== 
    04. Banner
=================================================================== */

.banner-area {
	height: 100%;
	width: 100%;
	position: relative;
}

@media (max-width: 1023px) {
	.banner-area {
		height: auto;
	}
}

.banner-area div {
	height: 100%;
}

.banner-area div.swiper-slide .row div {
	height: auto;
}

.banner-area.top-pad-80 .content {
	padding-top: 80px;
}

@media (max-width: 1023px) {
	.banner-area.top-pad-80 .content {
		padding-top: 200px;
	}
}

@media only screen and (max-width: 767px) {
	.banner-area.top-pad-80 .content {
		padding-top: 140px;
	}
}

.banner-area.top-pad-90 .content {
	padding-top: 90px;
}

@media (max-width: 1023px) {
	.banner-area.top-pad-90 .content {
		padding-top: 200px;
	}
}

@media only screen and (max-width: 767px) {
	.banner-area.top-pad-90 .content {
		padding-top: 140px;
	}
}

.banner-area.top-pad-100 .content {
	padding-top: 100px;
}

@media (max-width: 1023px) {
	.banner-area.top-pad-100 .content {
		padding-top: 200px;
	}
}

@media only screen and (max-width: 767px) {
	.banner-area.top-pad-100 .content {
		padding-top: 140px;
	}
}

.banner-area.top-pad-110 .content {
	padding-top: 110px;
}

@media (max-width: 1023px) {
	.banner-area.top-pad-110 .content {
		padding-top: 200px;
	}
}

@media only screen and (max-width: 767px) {
	.banner-area.top-pad-110 .content {
		padding-top: 140px;
	}
}

.banner-area.top-pad-120 .content {
	padding-top: 120px;
}

@media (max-width: 1023px) {
	.banner-area.top-pad-120 .content {
		padding-top: 200px;
	}
}

@media only screen and (max-width: 767px) {
	.banner-area.top-pad-120 .content {
		padding-top: 140px;
	}
}

.banner-area.top-pad-130 .content {
	padding-top: 130px;
}

@media (max-width: 1023px) {
	.banner-area.top-pad-130 .content {
		padding-top: 200px;
	}
}

@media only screen and (max-width: 767px) {
	.banner-area.top-pad-130 .content {
		padding-top: 140px;
	}
}

.banner-area.top-pad-150 .content {
	padding-top: 150px;
}

@media (max-width: 1023px) {
	.banner-area.top-pad-150 .content {
		padding-top: 200px;
	}
}

@media only screen and (max-width: 767px) {
	.banner-area.top-pad-150 .content {
		padding-top: 140px;
	}
}

.banner-area.auto-height {
	height: auto;
}

.banner-area.auto-height div {
	height: auto;
}

.banner-area.auto-height .content {
	padding: 200px 0;
}

@media only screen and (max-width: 767px) {
	.banner-area.auto-height .content {
		padding: 60px 0;
	}
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
	.banner-area.auto-height .content {
		padding: 120px 0;
	}
}

.banner-area.auto-height .content .thumb {
	padding-left: 35px;
}

@media (max-width: 991px) {
	.banner-area.auto-height .content .thumb {
		padding-left: 0;
		margin-top: 50px;
	}
}

.banner-area.auto-height.inc-header-transparent .content {
	padding-top: 250px;
}

@media only screen and (max-width: 767px) {
	.banner-area.auto-height.inc-header-transparent .content {
		padding-top: 140px;
	}
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
	.banner-area.auto-height.inc-header-transparent .content {
		padding-top: 220px;
	}
}

.banner-area.auto-height.inc-header-transparent.double-items.bottom-thumb .content .info {
	padding-top: 80px;
}

.banner-area.auto-height.inc-header-transparent.double-items.bottom-thumb .content .thumb {
	margin-top: 250px;
}

@media only screen and (max-width: 767px) {
	.banner-area.auto-height.inc-header-transparent.double-items.bottom-thumb .content .thumb {
		margin-top: 30px;
	}
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
	.banner-area.auto-height.inc-header-transparent.double-items.bottom-thumb .content .thumb {
		margin-top: 50px;
	}
}

.banner-area .content {
	position: relative;
	z-index: 9;
	-webkit-transition: all 500ms ease;
	transition: all 500ms ease;
}

@media (max-width: 1023px) {
	.banner-area .content {
		padding: 120px 0;
	}
}

@media only screen and (max-width: 767px) {
	.banner-area .content {
		padding: 60px 0;
	}
}

.banner-area h4 {
	-webkit-transition: all 500ms ease;
	transition: all 500ms ease;
	-webkit-transform: translate3d(-15%, 0, 0);
	transform: translate3d(-15%, 0, 0);
	opacity: 0;
	visibility: hidden;
}

.banner-area h2 {
	-webkit-transition: all 500ms ease;
	transition: all 500ms ease;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	opacity: 0;
	visibility: hidden;
}

.banner-area p {
	-webkit-transition: all 500ms ease;
	transition: all 500ms ease;
	-webkit-transform: translateY(50%);
	transform: translateY(50%);
	opacity: 0;
	padding-right: 25%;
	margin: 0;
	visibility: hidden;
}

@media (max-width: 1023px) {
	.banner-area p {
		padding-right: 0;
	}
}

.banner-area.text-center p {
	padding-left: 13%;
	padding-right: 13%;
}

@media (max-width: 1023px) {
	.banner-area.text-center p {
		padding: 0;
	}
}

.banner-area .thumb {
	-webkit-transition: all 500ms ease;
	transition: all 500ms ease;
	opacity: 0;
	visibility: hidden;
}

.banner-area .button {
	-webkit-transition: all 500ms ease;
	transition: all 500ms ease;
	-webkit-transform: translateY(50%);
	transform: translateY(50%);
	opacity: 0;
	visibility: hidden;
	margin-top: 30px;
}

.banner-area .banner-slide h4 {
	-webkit-transform: translateY(50%);
	transform: translateY(50%);
}

.banner-area .banner-slide h2 {
	-webkit-transform: translateY(50%);
	transform: translateY(50%);
}

.banner-area .banner-slide p {
	-webkit-transform: translateY(50%);
	transform: translateY(50%);
}

.banner-area .banner-slide .button {
	-webkit-transform: translateY(50%);
	transform: translateY(50%);
}

.banner-area .banner-slide .swiper-slide.swiper-slide-active h4 {
	-webkit-transform: translateY(0);
	transform: translateY(0);
	-webkit-transition-delay: 1200ms;
	transition-delay: 1200ms;
}

.banner-area .banner-slide .swiper-slide.swiper-slide-active h2 {
	-webkit-transform: translateY(0);
	transform: translateY(0);
	-webkit-transition-delay: 1400ms;
	transition-delay: 1400ms;
}

.banner-area .banner-slide .swiper-slide.swiper-slide-active p {
	-webkit-transform: translateY(0);
	transform: translateY(0);
	-webkit-transition-delay: 1600ms;
	transition-delay: 1600ms;
}

.banner-area .banner-slide .swiper-slide.swiper-slide-active .button {
	-webkit-transform: translateY(0);
	transform: translateY(0);
	-webkit-transition-delay: 1800ms;
	transition-delay: 1800ms;
}

.banner-area.double-items .thumb {
	-webkit-transform: translateY(50%);
	transform: translateY(50%);
}

.banner-area.double-items .swiper-slide.swiper-slide-active .thumb {
	-webkit-transform: translateY(0);
	transform: translateY(0);
	-webkit-transition-delay: 500ms;
	transition-delay: 500ms;
	visibility: visible;
	opacity: 1;
}

.banner-area.double-items.bottom-thumb .content {
	padding: 0;
}

.banner-area.double-items.bottom-thumb .content .thumb {
	margin-top: 120px;
}

@media only screen and (max-width: 767px) {
	.banner-area.double-items.bottom-thumb .content {
		padding: 50px 0;
	}

	.banner-area.double-items.bottom-thumb .content .thumb {
		margin-top: 30px;
	}
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
	.banner-area.double-items.bottom-thumb .content {
		padding: 120px 0;
	}

	.banner-area.double-items.bottom-thumb .content .thumb {
		margin-top: 50px;
	}
}

.banner-area.zoom-effect .banner-thumb {
	-webkit-transition: 10s ease-out;
	transition: 10s ease-out;
	-webkit-transform: scale(1.1);
	transform: scale(1.1);
	position: absolute;
	left: 0;
	top: 0;
	content: "";
	height: 100%;
	width: 100%;
}

.banner-area.zoom-effect .swiper-slide.swiper-slide-active .banner-thumb {
	-webkit-transform: scale(1);
	transform: scale(1);
}

.banner-area .swiper-notification {
	display: none;
}

.banner-area .swiper-slide.swiper-slide-active h4 {
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
	opacity: 1;
	visibility: visible;
	-webkit-transition-delay: 800ms;
	transition-delay: 800ms;
}

.banner-area .swiper-slide.swiper-slide-active h2 {
	-webkit-transform: translateY(0);
	transform: translateY(0);
	opacity: 1;
	visibility: visible;
	-webkit-transition-delay: 1600ms;
	transition-delay: 1600ms;
}

.banner-area .swiper-slide.swiper-slide-active p {
	-webkit-transform: translateY(0);
	transform: translateY(0);
	opacity: 1;
	visibility: visible;
	-webkit-transition-delay: 2400ms;
	transition-delay: 2400ms;
}

.banner-area .swiper-slide.swiper-slide-active .button {
	-webkit-transform: translateY(0);
	transform: translateY(0);
	opacity: 1;
	visibility: visible;
	-webkit-transition-delay: 3200ms;
	transition-delay: 3200ms;
}

.banner-area.banner-style-one .swiper-slide.swiper-slide-active .button {
	-webkit-transition-delay: 2400ms;
	transition-delay: 2400ms;
}

.banner-area .banner-items {
	position: relative;
	width: 100%;
	height: 100%;
	z-index: 1;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-transition-property: -webkit-transform;
	transition-property: -webkit-transform;
	transition-property: transform;
	transition-property: transform, -webkit-transform;
	-webkit-box-sizing: content-box;
	box-sizing: content-box;
	-webkit-transform: translate3d(0px, 0, 0);
	transform: translate3d(0px, 0, 0);
}

.banner-area:hover .swiper-button-prev,
.banner-area:hover .swiper-button-next {
	opacity: 1;
}

.banner-area:hover .swiper-button-prev {
	left: 30px;
	right: auto;
}

.banner-area:hover .swiper-button-next {
	right: 30px;
	left: auto;
}

.banner-area .swiper-button-prev,
.banner-area .swiper-button-next {
	height: auto;
	-webkit-transition: all 0.35s ease-in-out;
	transition: all 0.35s ease-in-out;
	opacity: 0;
}

.banner-area .swiper-button-prev::after,
.banner-area .swiper-button-next::after {
	font-size: 28px;
	color: var(--white);
}

.banner-area.navigation-circle .swiper-button-prev,
.banner-area.navigation-circle .swiper-button-next {
	height: 60px;
	width: 60px;
	line-height: 60px;
	background: rgba(0, 0, 0, 0.5);
	border-radius: 50%;
}

.banner-area.navigation-circle .swiper-button-prev::after,
.banner-area.navigation-circle .swiper-button-next::after {
	font-size: 18px;
}

.banner-area.navigation-right-bottom .swiper-button-prev,
.banner-area.navigation-right-bottom .swiper-button-next {
	left: auto;
	right: 30px;
	opacity: 1;
	top: auto;
	-webkit-transform: inherit;
	transform: inherit;
	bottom: 50px;
	border-radius: inherit;
}

.banner-area.navigation-right-bottom .swiper-button-prev {
	right: 92px;
}

.banner-area.navigation-between-bottom .content {
	padding-bottom: 105px;
}

@media only screen and (min-width: 829px) and (max-width: 1023px) {
	.banner-area.navigation-between-bottom .content {
		padding-bottom: 220px;
	}
}

.banner-area.navigation-between-bottom .swiper-button-prev,
.banner-area.navigation-between-bottom .swiper-button-next {
	left: auto;
	right: 30px;
	opacity: 1;
	top: auto;
	-webkit-transform: inherit;
	transform: inherit;
	bottom: 30px;
	border-radius: inherit;
}

.banner-area.navigation-between-bottom .swiper-button-prev {
	left: 30px;
	right: auto;
}

@media only screen and (max-width: 830px) {

	.banner-area .swiper-button-prev,
	.banner-area .swiper-button-next {
		display: none;
	}
}

.banner-area.navigation-custom .swiper-button-prev::after {
	font-family: 'ElegantIcons';
	content: "\23";
	font-size: 22px;
}

.banner-area.navigation-custom .swiper-button-next::after {
	font-family: 'ElegantIcons';
	content: "\24";
	font-size: 22px;
}

.banner-area.navigation-text .swiper-button-prev,
.banner-area.navigation-text .swiper-button-next {
	opacity: 1;
	top: auto;
	-webkit-transform: inherit;
	transform: inherit;
	left: auto;
	right: 0;
	bottom: 0;
	display: inline-block;
	width: 150px;
	background: rgba(0, 0, 0, 0.2);
	text-align: center;
	height: 100px;
	line-height: 100px;
}

@media only screen and (max-width: 830px) {

	.banner-area.navigation-text .swiper-button-prev,
	.banner-area.navigation-text .swiper-button-next {
		display: none;
	}
}

.banner-area.navigation-text .swiper-button-prev {
	right: 151px;
}

.banner-area.navigation-text .swiper-button-prev::after {
	content: "Prev";
	text-transform: uppercase !important;
	font-size: 16px;
	font-weight: 800;
	letter-spacing: 0.6px;
}

.banner-area.navigation-text .swiper-button-next::after {
	content: "Next";
	text-transform: uppercase !important;
	font-size: 16px;
	font-weight: 800;
	letter-spacing: 0.6px;
}

.banner-area.navigation-icon-solid .swiper-button-prev::after,
.banner-area.navigation-icon-solid .swiper-button-next::after {
	font-size: 30px;
}

.banner-area.navigation-custom-large .swiper-button-prev,
.banner-area.navigation-custom-large .swiper-button-next {
	min-width: 65px;
	height: 30px;
	margin: 0;
}

.banner-area.navigation-custom-large .swiper-button-prev::after {
	font-weight: 100;
	font-size: 30px;
	position: absolute;
	left: 8px;
}

.banner-area.navigation-custom-large .swiper-button-prev::before {
	position: absolute;
	top: 50%;
	right: inherit;
	bottom: inherit;
	left: 12px;
	content: "";
	height: 2px;
	width: 50px;
	z-index: -1;
	background-color: var(--white);
	margin-top: -1px;
}

.banner-area.navigation-custom-large .swiper-button-next::after {
	font-family: "Font Awesome 5 Pro";
	content: "\f105";
	font-weight: 100;
	font-size: 30px;
	position: absolute;
	right: 8px;
}

.banner-area.navigation-custom-large .swiper-button-next::before {
	position: absolute;
	top: 50%;
	right: 12px;
	bottom: inherit;
	left: inherit;
	content: "";
	height: 2px;
	width: 50px;
	z-index: -1;
	background-color: var(--white);
	margin-top: -1px;
}

.banner-area.navigation-right-bottom .swiper-button-prev,
.banner-area.navigation-right-bottom .swiper-button-next {
	position: absolute;
	left: auto;
	right: 30px;
	top: auto;
	bottom: 40px;
	opacity: 1;
	-webkit-transform: inherit;
	transform: inherit;
}

.banner-area.navigation-right-bottom .swiper-button-prev {
	right: 60px;
}

.banner-area.navigation-right-bottom.navigation-custom-large .swiper-button-prev,
.banner-area.navigation-right-bottom.navigation-custom-large .swiper-button-next {
	bottom: 30px;
}

.banner-area.navigation-right-bottom.navigation-custom-large .swiper-button-prev {
	right: 100px;
}

.banner-area .swiper-pagination {
	height: auto;
	bottom: 35px;
}

.banner-area .swiper-pagination span.swiper-pagination-bullet {
	height: 4px;
	width: 50px;
	background: rgba(255, 255, 255, 0.5);
	opacity: 1;
	border-radius: inherit;
	-webkit-transition: all 0.35s ease-in-out;
	transition: all 0.35s ease-in-out;
}

.banner-area .swiper-pagination span.swiper-pagination-bullet.swiper-pagination-bullet-active {
	background: white;
	height: 7px;
}

@media only screen and (max-width: 767px) {
	.banner-area.include-pagination .content {
		padding-bottom: 100px;
	}
}

.banner-area .swiper-pagination-fraction span {
	-webkit-transition: all 0.35s ease-in-out;
	transition: all 0.35s ease-in-out;
}

.banner-area .swiper-pagination-fraction span.swiper-pagination-current {
	font-size: 30px;
	font-weight: 800;
}

/* ------------- Banner Custom ------------- */

.banner-style-one .align-center {
	align-items: center;
}

.banner-style-one {
	padding-top: 150px;
}

.banner-style-one .info {
	margin-top: -80px;
}

.banner-style-one .thumb {
	position: relative;
	padding: 60px;
	padding-bottom: 0;
}

.banner-style-one .thumb .shape-center {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	z-index: -1;
	width: 100%;
}

.banner-style-one .info {
	position: relative;
}

.text-invisible {
	font-weight: 900;
	-webkit-background-clip: text;
	background-clip: text;
	-webkit-text-fill-color: transparent;
	background-size: cover;
	background-position: center;
	-webkit-text-stroke: 1px var(--color-heading);
	font-size: 101px;
	position: absolute;
	left: 0;
	top: -60px;
	opacity: 0.2;
	z-index: -1;
}

.banner-style-one h2 {
	font-size: 60px;
	font-weight: 900;
	line-height: 1.2;
	margin-bottom: 30px;
}

.banner-style-one h2 img {
	height: 50px;
}

.banner-style-one h3 {
	font-weight: 800;
	font-size: 30px;
	margin: 0;
	display: inline-block;
	position: relative;
	z-index: 1;
	margin-top: -15px;
}

.banner-style-one h3::after {
	position: absolute;
	left: 0;
	bottom: 5px;
	content: "";
	height: 5px;
	width: 100%;
	background: var(--color-secondary);
	z-index: -1;
}

.banner-style-one h3 .typed-cursor {
	font-weight: 100;
	font-size: 50px;
	position: relative;
	top: 8px;
	color: #202942;
	margin-left: 5px;
}

@media (min-width: 1024px) {
	.box-layout {
		max-width: 90%;
		margin: auto;
	}
}

.personal-social {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	right: 50px;
}

.personal-social li {
	margin: 30px 0;
	font-size: 18px;
}

.box-layout.bg-light {
	background: rgba(255, 255, 255, 0.5) !important;
}

.blur-bg {
	position: absolute;
	right: 0;
	top: 0;
	height: 300px;
	width: 300px;
	background: red;
	z-index: -1;
	border-radius: 50%;
	filter: blur(120px);
	opacity: 0.2;
}

.dark-layout .banner-style-one h3::after {
	background: var(--color-primary);
	width: 95%;
	height: 5px;
	opacity: 0.7;
}

/* ============================================================== 
     05. Services
=================================================================== */

.service-style-one-item img {
	height: 80px;
	margin-bottom: 50px;
}

.service-style-one-item {
	padding: 0 37px;
}

.service-style-one {
	margin-bottom: 30px;
	border-right: 1px solid rgba(80, 61, 205, 0.1);
}

.service-style-one-item p {
	margin: 0;
}

.service-style-one:last-child {
	border: none;
}

.service-style-one-item h4 {
	margin-bottom: 20px;
}

/* --- Services Details --- */
.services-single-content .thumb img {
	margin-bottom: 40px;
}

.services-single-content h1,
.services-single-content h2,
.services-single-content h3,
.services-single-content h4,
.services-single-content h5,
.services-single-content h6 {
	font-weight: 800;
}

.feature-list-item {
	margin: 0;
	padding: 0;
	list-style: none;
}

.feature-list-item li {
	position: relative;
	z-index: 1;
	padding-left: 27px;
	margin-top: 8px;
	font-weight: 600;
}

.feature-list-item li::after {
	position: absolute;
	left: 0;
	top: -1px;
	content: "\eb7b";
	font-family: "remixicon";
	color: var(--color-primary);
	font-weight: 500;
}

.services-more .item {
	padding: 50px;
	background: var(--bg-gray);
	margin-top: 15px;
	border-radius: 10px;
}

.services-more .item i {
	display: inline-block;
	font-size: 40px;
	margin-bottom: 30px;
	background: var(--color-primary);
	color: var(--white);
	height: 70px;
	width: 70px;
	text-align: center;
	line-height: 70px;
	border-radius: 50%;
}

.services-more .item i::before {
	line-height: inherit;
}

.services-more .item a {
	color: var(--color-heading);
}

.services-more .item a:hover {
	color: var(--color-primary);
}

.services-more .item p {
	margin: 0;
}

.services-single-content .faq-style-one button.accordion-button {
	background: #fbfbfb !important;
	border: 1px solid #f3ecec !important;
	padding: 20px 25px !important;
	padding-right: 60px !important;
	font-weight: 800;
}

.services-single-content .faq-style-one button.accordion-button::after {
	top: 15px;
}

.dark-layout .box-layout.bg-light {
	background: rgba(0, 0, 0, 0.2) !important;
}

.dark-layout .services-single-content .faq-style-one button.accordion-button {
	background: #012b45 !important;
	border: 1px solid rgba(255, 255, 255, 0.1) !important;
	color: var(--white);
}

.dark-layout .services-more .item {
	background: #012b45;
}

.dark-layout .services-more .item a {
	color: var(--white);
}

.dark-layout .services-more .item a:hover {
	color: var(--color-primary);
}

/* ------------- Faq ------------- */

.faq-style-one button.accordion-button {
	background: var(--white);
	color: var(--color-heading);
	border: none;
	-webkit-box-shadow: inherit;
	box-shadow: inherit;
	font-size: 16px;
	font-weight: 600;
	padding: 25px !important;
	background: var(--white) !important;
	border-radius: 10px !important;
	padding-right: 60px !important;
	position: relative;
}

.faq-style-one button.accordion-button::after {
	background: transparent;
	content: "\ea78";
	font-family: "remixicon";
	font-weight: 100;
	font-size: 25px;
	position: absolute;
	top: 20px;
	right: 25px;
}

.faq-style-one button.accordion-button:not(.collapsed)::after {
	transform: inherit;
	content: "\ea50";
	font-family: "remixicon";
	font-size: 35px;
	margin-top: -5px;
	right: 30px;
	font-weight: 100;
}

.faq-style-one button.accordion-button:focus {
	background: transparent;
	color: var(--color-heading);
	border: none;
	-webkit-box-shadow: inherit;
	box-shadow: inherit;
	padding-left: 0;
	font-weight: 800;
}

.faq-style-one .accordion-item {
	background: transparent;
	border: none;
	margin-top: 15px;
}

.faq-style-one .accordion-item h2 {
	margin: 0;
}

.faq-style-one .accordion-body {
	padding-left: 0;
}

.faq-style-one .accordion-body p {
	margin: 0;
}

.faq-style-one button.accordion-button strong {
	display: inline-block;
	height: 35px;
	width: 35px;
	line-height: 34px;
	text-align: center;
	border-radius: 50%;
	margin-right: 12px;
	border: 1px solid #e7e7e7;
}

/* ------------- Fun Factor ------------- */

.fun-factor-default .counter {
	position: relative;
	display: flex;
	align-items: center;
	font-size: 45px;
	font-weight: 900;
	color: var(--color-heading);
	line-height: 1;
	margin-bottom: 5px;
}

.dark-layout .fun-factor-default .counter {
	color: var(--white);
}

.fun-factor-default .medium {
	font-weight: 800;
}

.fun-factor-default .fun-fact {
	float: left;
	border-right: 1px solid #c1bdbd;
	padding-right: 50px;
	margin-right: 50px;
	margin-top: 35px !important;
}

.fun-factor-default .fun-fact:last-child {
	margin: 0;
	padding: 0;
	border: none;
}

.shape-animated-right {
	position: absolute;
	right: 5%;
	top: -60px;
	max-width: 200px;
}

.shape-animated-left {
	position: absolute;
	left: 3%;
	top: 100px;
	max-width: 80px;
}


/* ============================================================== 
     06. Projects / Portfolio / Gallery
=================================================================== */

.gallery-area .magnific-mix-gallery {
	margin: -15px;
}

.gallery-items.columns-3 .pf-item {
	float: left;
	padding: 15px;
	width: 33.3333%;
}

.gallery-items.columns-2 .pf-item {
	float: left;
	padding: 15px;
	width: 50%;
}

.gallery-items.columns-4 .pf-item {
	float: left;
	padding: 15px;
	width: 25%;
}

.gallery-items .pf-item .overlay {
	position: relative;
	z-index: 1;
	overflow: hidden;
	border-radius: 10px;
}

.gallery-items .pf-item .overlay:after {
	content: "";
	position: absolute;
	height: 100%;
	width: 100%;
	left: 170%;
	top: 0;
	transform: skewX(45deg);
	transition: all 0.55s ease-in-out;
	background-color: var(--color-primary);
	opacity: 0.6;
}

.gallery-items .pf-item .overlay:hover:after {
	left: -170%;
	top: 0;
}

.gallery-items .pf-item .overlay:before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.7);
	opacity: 0;
	transition: all 0.5s ease 0s;
}

.gallery-items .pf-item .overlay:hover:before {
	opacity: 1;
}

.gallery-items .pf-item .overlay .content {
	position: absolute;
	left: 40px;
	bottom: 30px;
	z-index: 1;
}

.gallery-items .pf-item .overlay .content .title {
	transition: all 0.35s ease-in-out;
	transform: translateX(50px);
	opacity: 0;
}

.gallery-items .pf-item .overlay:hover .content .title {
	transform: translateX(0);
	opacity: 1;
}

.gallery-items .pf-item .overlay .content .title span {
	color: #cccccc;
	display: block;
	margin-bottom: 3px;
}

.gallery-items .pf-item .overlay .content .title a {
	color: var(--white);
}

.gallery-items .pf-item .overlay .content>a {
	display: inline-block;
	height: 50px;
	width: 50px;
	line-height: 50px;
	text-align: center;
	background: var(--color-primary);
	color: var(--white);
	border-radius: 50%;
	transform: translateY(30px);
	opacity: 0;
}

.gallery-items .pf-item .overlay .content>a i {
	font-weight: 500;
}

.gallery-items .pf-item .overlay:hover .content>a {
	transform: translateY(0);
	opacity: 1;
}

.gallery-items .pf-item .overlay .content .title h5 {
	font-size: 20px;
}

.pf-item .overlay-content .content {
	padding: 15px 30px;
	position: absolute;
	z-index: 1;
	display: flex;
	align-items: center;
	justify-content: space-between;
	left: 0;
	bottom: -50px;
	right: 0;
	background: var(--white);
	transition: all 0.35s ease-in-out;
	opacity: 0;
	visibility: hidden;
}

.pf-item .overlay-content:hover .content {
	bottom: 0;
	opacity: 1;
	visibility: visible;
}

.pf-item .overlay-content::before {
	content: "";
	position: absolute;
	background: inherit;
	z-index: -1;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	box-shadow: inset 0 0 2000px rgba(255, 255, 255, .5);
	filter: blur(10px);
	margin: -20px;
}

.pf-item .overlay-content {
	position: relative;
	z-index: 1;
	overflow: hidden;
	transition: all 0.35s ease-in-out;
}

.pf-item .overlay-content .content>a {
	display: inline-block;
	height: 50px;
	width: 50px;
	text-align: center;
	line-height: 45px;
	background: var(--white);
	border-radius: 50%;
	border: 3px solid var(--dark);
	position: relative;
	left: -30px;
	opacity: 0;
	visibility: hidden;
	transition-delay: 0.20s;
}

.pf-item .overlay-content .content>a i {
	font-weight: 500;
	font-size: 22px;
	top: 0px;
}

.pf-item .overlay-content:hover .content>a {
	left: 0;
	opacity: 1;
	visibility: visible;
}

.pf-item .overlay-content .content h5 {
	margin: 0;
}

.pf-item .overlay-content .content span {
	font-weight: 600;
}

.magnific-mix-gallery {
	margin: -15px;
}

/* ------------- Project Details ------------- */

.project-details-items .top-info {
	position: relative;
	margin-top: 50px;
}

.project-details-items .top-info p:last-child {
	margin-bottom: 0;
}

.project-details-items .main-content h2,
.project-details-items .main-content h3,
.project-details-items .main-content h4,
.project-details-items .main-content h5 {
	font-weight: 800;
	margin-bottom: 25px;
}

.project-details-items .main-content .row img {
	margin-top: 30px;
}

.project-details-items .top-info h2 {
	font-weight: 800;
	margin-bottom: 20px;
}

.project-thumb {
	position: relative;
	z-index: 1;
}

.project-info {
	border: 1px solid #e7e7e7;
}

.project-info .content {
	padding: 50px;
	padding-top: 40px;
	padding-bottom: 45px;
}

.project-info .social li a i:before {
	font-size: 19px;
	display: inline-block;
	position: relative;
	top: -3px;
}

.project-info .title {
	background: var(--color-primary);
	color: var(--white);
	padding: 15px 50px;
	font-weight: 500;
	margin: 0;
}

.project-info .project-basic-info {
	padding-left: 0;
	list-style: none;
	margin: 0;
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-column-gap: 30px;
	grid-row-gap: 15px;
}

.project-info .project-basic-info li {
	font-weight: 800;
	color: var(--color-heading);
	text-transform: uppercase;
}

.project-info .project-basic-info li span {
	font-weight: 400;
	position: relative;
	display: block;
	font-family: var(--font-default);
	color: var(--color-paragraph);
	text-transform: capitalize;
}

.project-info ul.social {
	margin-top: 25px;
	border-top: 1px solid #e7e7e7;
	padding-top: 25px;
}

.project-info ul.social li {
	display: inline-block;
	margin: 0;
	margin-left: 5px;
	margin-top: 5px;
}

.project-info ul.social li:first-child {
	margin-left: 0;
}

.project-info ul.social li a {
	display: inline-block;
	border-radius: 50%;
	font-size: 14px;
	font-weight: 100;
	height: 38px;
	width: 38px;
	text-align: center;
	line-height: 40px;
	background: var(--dark);
	color: var(--white);
}

.project-info ul.social li a:hover {
	background: var(--color-primary);
}

.project-info ul.social li:last-child {
	margin-right: 0;
}

.project-info ul.social h4 {
	margin: 0;
	display: inline-block;
	text-transform: capitalize;
	font-weight: 800;
	margin-right: 10px;
}

.project-details-items .check-list {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-column-gap: 30px;
	grid-row-gap: 30px;
}

.project-details-items .check-list h4 {
	font-weight: 800;
	position: relative;
	z-index: 1;
	padding-left: 40px;
}

.project-details-items .check-list h4::after {
	position: absolute;
	left: 0;
	top: 0;
	content: "\eb7b";
	font-family: "remixicon";
	height: 28px;
	width: 28px;
	text-align: center;
	line-height: 28px;
	font-size: 12px;
	border-radius: 50%;
	border: 1px solid #c9c9c9;
	font-size: 15px;
}

.project-details-items .check-list p {
	margin: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.project-details-items .top-info {
		margin-top: 100px;
	}
}

.dark-layout .pf-item .overlay-content .content {
	background: var(--dark);
}

.dark-layout .pf-item .overlay-content .content>a {
	background: var(--color-primary);
}

.dark-layout .project-info {
	background: #012b45;
	border: none;
}

.dark-layout .project-info .project-basic-info li {
	color: var(--white);
}

.dark-layout .project-info .project-basic-info li span {
	color: var(--white);
	opacity: 0.8;
}

.dark-layout .project-info ul.social {
	border-color: rgba(255, 255, 255, 0.3);
}

.dark-layout .project-details-items .check-list h4::after {
	background: #012b45;
	font-size: 10px;
	border: 1px solid rgba(255, 255, 255, 0.3);
}

/* ------------- Typed Text ------------- */

.cd-words-wrapper {
	display: inline-block;
	position: relative;
	text-align: left;
}

.cd-words-wrapper b {
	display: inline-block;
	position: absolute;
	white-space: nowrap;
	left: 0;
	top: 0;
}

.cd-words-wrapper b.is-visible {
	position: relative;
}

.no-js .cd-words-wrapper b {
	opacity: 0;
}

.no-js .cd-words-wrapper b.is-visible {
	opacity: 1;
}

/* ------ clip ----- */

.cd-headline.clip span {
	display: inline-block;
	padding: 0;
}

.cd-headline.clip .cd-words-wrapper {
	overflow: hidden;
	vertical-align: middle;
}

.cd-headline.clip b {
	opacity: 0;
}

.cd-headline.clip b.is-visible {
	opacity: 1;
}

/* ============================================================== 
     07. About
=================================================================== */

.skill-list ul {
	margin-top: 30px;
	position: relative;
	z-index: 1;
}

.skill-list li {
	display: flex;
	align-items: center;
	border-top: 1px solid #ffffff;
	margin-top: 15px;
	padding-top: 15px;
}

.skill-list li img {
	width: 60px;
	margin-right: 19px;
}

.skill-list li h4 {
	font-weight: 700;
	margin-bottom: 5px;
}

.skill-list li:first-child {
	margin: 0;
	padding: 0;
	border: none;
}

.sub-title {
	font-weight: 700;
	text-transform: uppercase;
	color: var(--color-primary);
}

.title {
	font-weight: 800;
	margin-bottom: 25px;
}

.skill-list li span {
	font-size: 17px;
	font-weight: 800;
}

.about-style-six .thumb {
	position: relative;
	z-index: 1;
	margin-bottom: 120px;
}

.about-style-six .thumb::after {
	position: absolute;
	left: 50%;
	bottom: -120px;
	content: "";
	height: 500px;
	width: 500px;
	transform: translateX(-50%);
	background: url(../img/shape/9.png);
	border-radius: 50%;
	z-index: -1;
	background-repeat: no-repeat;
	background-size: contain;
}

.skill-list li i {
	display: inline-block;
	height: 70px;
	width: 70px;
	text-align: center;
	line-height: 70px;
	background: var(--color-primary);
	margin-right: 20px;
	border-radius: 50%;
	font-size: 40px;
	color: var(--white);
}

.skill-list li i.ti-wordpress {
	background: #21759b;
}

.skill-list li i.fa-shopify {
	background: #96bf48;
}

.skill-list li i.fa-figma {
	background: #9d56f7;
}

.skill-list li i.ri-stack-line {
	background: #7952b3;
	font-size: 33px;
}

/* ============================================================== 
     08. CV / Resume / Personal Information
=================================================================== */

.resume-tab-navs {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	border: none;
	margin: 0;
	margin-bottom: 50px;
	padding: 10px;
	border-radius: 50px;
	grid-column-gap: 15px;
	box-shadow: 0 0 1rem 0 rgb(0 0 0 / 10%);
	position: relative;
	z-index: 1;
	background: var(--white);
}

.resume-tab-navs::after {
	display: none;
}

.resume-tab-navs:before {
	content: "";
	position: absolute;
	background: inherit;
	z-index: -1;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	box-shadow: inset 0 0 2000px rgba(255, 255, 255, .5);
	border-radius: 50px;
}

.resume-tab-navs button {
	border: none !important;
	margin: 0;
	padding: 15px;
	border-radius: 50px !important;
	background: var(--white) !important;
}

.resume-tab-navs button {
	margin: 0;
	transition: all 0.35s ease-in-out;
	text-transform: uppercase;
	font-size: 15px;
	font-weight: 800;
	display: flex;
	align-items: center;
	justify-content: center;
	color: var(--color-heading);
}

.resume-tab-navs button strong {
	display: inline-block;
	margin-left: 60px;
	position: relative;
	z-index: 1;
	font-size: 25px;
}

.resume-tab-navs button strong::after {
	left: -45px;
	top: 50%;
	content: "";
	height: 1px;
	width: 30px;
	background: var(--color-heading);
	position: absolute;
	z-index: -1;
	transition: all 0.35s ease-in-out;
}

.resume-tab-navs button.active strong::after,
.resume-tab-navs button.nav-link:hover strong::after {
	background: rgba(255, 255, 255, 0.9);
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-link:hover {
	background: var(--dark) !important;
	color: var(--white) !important;
}

.nav-tabs .nav-link.active button,
.nav-tabs .nav-link:hover button {
	color: var(--white);
}

.resume-tab-content {
	position: relative;
}

ul.biography-table {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-column-gap: 70px;
	overflow: hidden;
}

ul.biography-table li {
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: relative;
	padding: 10px 0;
	z-index: 1;
}

ul.biography-table li::after {
	position: absolute;
	left: 0;
	top: -1px;
	content: "";
	height: 1px;
	width: 100%;
	background: #cfd4d6;
}

ul.biography-table li h5 {
	margin: 0;
}

ul.biography-table li p {
	margin: 0;
}

.experience-content-box {
	position: relative;
	z-index: 1;
	padding-bottom: 60px;
}

.experience-content-box::after {
	position: absolute;
	left: 0;
	top: 50px;
	content: "";
	bottom: 0;
	background: rgba(255, 255, 255, 0.5) !important;
	width: 100%;
	border: 2px solid rgba(255, 255, 255, 0.9);
}

.shape-right-top-extra {
	position: absolute;
	right: 5%;
	top: 0;
	max-width: 10%;
}

.skill-table {
	z-index: 1;
	position: relative;
}

.skill-table li {
	padding: 10px 0;
}

.skill-table li:first-child {
	border: none;
	padding-top: 0;
}

.skill-table i {
	display: inline-block;
	font-size: 30px;
	height: 65px;
	width: 65px;
	line-height: 67px;
	text-align: center;
	border-radius: 50%;
	color: #ffffff;
}

.skill-table i.fa-php {
	color: #8993be;
}

.skill-table .progress-box h5 {
	margin: 0;
	float: left;
	margin-right: 15px;
	margin-top: -14px;
	font-size: 30px;
	font-weight: 800;
}

.skill-table h4 {
	margin: 0;
	font-weight: 800;
}

.skill-table .progress {
	height: 8px;
	border-radius: 50px;
	background: #e5ebec;
	margin-top: 15px;
}

.skill-table .progress .progress-bar {
	background: var(--color-primary);
	border-radius: 50px;
}

/* ------------- Animated Progress Bar ------------- */

.skill-table .progress .progress-bar-animated {
	animation: progressAnimation 4s;
}

@keyframes progressAnimation {
	from {
		width: 0
	}

	to {
		width: 75%
	}
}

.skill-table .progress .progress-bar-animated2 {
	animation: progressAnimation2 4s;
}

@keyframes progressAnimation2 {
	from {
		width: 0
	}

	to {
		width: 84%
	}
}

.skill-table .progress .progress-bar-animated3 {
	animation: progressAnimation3 4s;
}

@keyframes progressAnimation3 {
	from {
		width: 0
	}

	to {
		width: 92%
	}
}

.skill-table .progress-box {
	align-items: center;
	width: 100%;
}

.skill-table i.ri-android-fill {
	background: #9CC83D;
}

.skill-table i.ri-html5-fill {
	background: #e34c26;
}

.skill-table i.ri-reactjs-fill {
	background: #61DBFB;
}

.education-table {
	position: relative;
	z-index: 1;
}

.education-table {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-column-gap: 60px;
	grid-row-gap: 40px;
}

.education-table p {
	margin: 0;
}

.education-table span {
	display: inline-block;
	background: var(--color-primary);
	color: var(--white);
	font-weight: 800;
	padding: 0 10px;
	margin-bottom: 15px;
}

.education-table h5 {
	text-transform: uppercase;
	font-size: 14px;
	margin-bottom: 20px;
}

.education-table li {
	border-left: 1px solid var(--color-primary);
	padding-left: 30px;
	position: relative;
}

.education-table li::after {
	position: absolute;
	left: -10px;
	top: 0;
	content: "";
	height: 20px;
	width: 20px;
	background: var(--color-primary);
	border-radius: 50%;
}

.education-table li::before {
	position: absolute;
	left: -6px;
	top: 4px;
	content: "";
	height: 12px;
	width: 12px;
	background: var(--white);
	border-radius: 50%;
	z-index: 1;
}

.dark-layout .skill-list li {
	border-color: rgba(255, 255, 255, 0.2);
}

.dark-layout .skill-list li span {
	opacity: 0.6;
	font-weight: 400;
}

.dark-layout .resume-tab-navs {
	background: #012b45;
	border: 1px solid rgba(255, 255, 255, 0.2);
}

.dark-layout .nav-tabs .nav-link.active,
.dark-layout .nav-tabs .nav-link:hover {
	background: var(--color-primary) !important;
}

.dark-layout .resume-tab-navs button {
	color: var(--white);
	background: transparent !important;
}

.dark-layout .resume-tab-navs button strong::after {
	background: var(--white);
	opacity: 0.6;
}

.dark-layout .experience-content-box::after {
	background: rgba(0, 0, 0, 0.1) !important;
	border: 1px solid rgba(255, 255, 255, 0.2);
}

.dark-layout ul.biography-table li::after {
	background: var(--white);
	opacity: 0.2;
}

.dark-layout .education-table li::before {
	background: var(--dark);
}

/* ============================================================== 
     09. Pricing Table
=================================================================== */

.pricing-style-one {
	background: rgba(255, 255, 255, 0.6);
	display: flex;
	justify-content: space-between;
	padding: 30px;
	margin-top: 30px;
	border: 2px solid var(--color-primary);
	position: relative;
	z-index: 1;
	overflow: hidden;
	transition: all 0.35s ease-in-out;
}

.pricing-style-one:nth-child(2n) {
	border-color: var(--white);
}

.pricing-style-one:first-child {
	margin-top: 0;
}

.pricing-style-one li:after {
	position: absolute;
	left: 0;
	top: 4px;
	content: "\eb7b";
	font-family: "remixicon";
	height: 20px;
	width: 20px;
	line-height: 18px;
	text-align: center;
	border: 1px solid var(--dark);
	color: var(--dark);
	font-size: 10px;
	border-radius: 50%;
	font-weight: 600;
	font-size: 12px;
}

.pricing-style-one:first-child li::after {
	color: var(--color-primary);
	border-color: var(--color-primary);
}

.pricing-style-one li {
	position: relative;
	padding-left: 30px;
	margin-top: 9px;
	font-weight: 500;
}

.pricing-style-one ul {
	list-style: none;
}

.pricing-style-one .price {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	justify-content: space-between;
	text-align: right;
}

.pricing-style-one i {
	display: inline-block;
	font-size: 60px;
	position: absolute;
	bottom: 30px;
	font-weight: 100;
}

.pricing-style-one i.ti-wordpress {
	color: #21759b;
}

.pricing-style-one i.ti-html5 {
	color: #e34c26;
}

.pricing-style-one .price h2 {
	margin: 0;
	font-weight: 800;
	font-size: 40px;
}

.pricing-style-one h4 {
	font-weight: 800;
	margin-bottom: 20px;
}

.save-amount {
	position: relative;
	padding: 50px;
	background: var(--white);
	border-radius: 30px;
	padding-left: 100px;
}

.save-amount img {
	position: absolute;
	left: -120px;
	bottom: -31px;
	max-width: 280px;
	animation: UpDown 2s linear infinite;
}

.save-amount h4 {
	font-weight: 800;
	line-height: 1.4;
	margin: 0;
	font-size: 28px;
}

.save-amount h4 strong {
	font-size: 36px;
	position: relative;
	z-index: 1;
	color: var(--color-primary);
}

.save-amount h4 strong::after {
	position: absolute;
	left: 0;
	bottom: 10px;
	content: "";
	height: 8px;
	width: 100%;
	background: var(--color-primary);
	z-index: -1;
	opacity: 0.2;
}

.dark-layout .save-amount {
	background: var(--dark);
}

.dark-layout .pricing-style-one {
	background: rgba(0, 0, 0, 0.2);
	border: 1px solid rgba(255, 255, 255, 0.1);
	border-radius: 10px;
}

.dark-layout .pricing-style-one:nth-child(2n) {
	border-color: rgba(255, 255, 255, 0.2);
	background: #012b45;
}

.dark-layout .pricing-style-one li:after {
	border-color: rgba(255, 255, 255, 0.3);
	color: var(--white);
	font-size: 9px;
	font-weight: 300;
}

/* ------------- Brands ------------- */

.brand-style-one {
	position: relative;
	padding-bottom: 120px;
}

.brand-style-one::after {
	position: absolute;
	bottom: 0;
	content: "";
	width: 80%;
	left: 50%;
	margin-left: -40%;
	display: block;
	background: linear-gradient(to right, rgba(255, 255, 255, 0), var(--white), rgba(255, 255, 255, 0));
	height: 1px;
}

.brand-style-one img {
	max-width: 80%;
	margin: auto;
}

.dark-layout .testimonial-style-one .provider span {
	font-weight: 900;
}

.dark-layout .brand-style-one img {
	opacity: 0.7;
}

.brands-carousel .react-multi-carousel-track {
	align-items: center;
}

/* ============================================================== 
   10. Testimonials 
=================================================================== */

.testimonial-style-one .thumb {
	position: relative;
	width: 250px;
	margin: auto auto 30px;
	border-radius: 0 0 480px 485px;
	overflow: hidden;
	z-index: 1;
}

.testimonial-style-one p {
	font-size: 22px;
	line-height: 1.5;
	font-weight: 600;
}

.testimonial-style-one .provider {
	margin-top: 30px;
}

.testimonial-style-one .provider h4 {
	margin-bottom: 5px;
	font-size: 22px;
}

.testimonial-style-one .provider span {
	color: var(--color-primary);
}

.testimonial-style-one .thumb .quote-thumb {
	position: absolute;
	left: 50%;
	bottom: -35px;
}

.testimonial-style-one .thumb .quote-thumb img:nth-child(2) {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	height: 16px;
	margin-top: -5px;
}

.testimonial-style-one .thumb::after {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	bottom: 0;
	content: "";
	height: 250px;
	width: 250px;
	background: var(--color-primary);
	z-index: -1;
	border-radius: 50%;
}

.testimonial-style-one .thumb .shape {
	position: absolute;
	left: 0;
	bottom: 0;
	height: 120px;
}

/* ============================================================== 
     11. Available
=================================================================== */

.side .widget .logo img {
	height: 40px;
}

.shape-right-top-mini {
	max-width: 200px;
	position: absolute;
	right: -50px;
	top: -50px;
}

.work-available-area {
	position: relative;
}

.work-available h2 {
	font-size: 60px;
	text-transform: capitalize;
	margin-bottom: 40px;
	line-height: 1.1;
	font-weight: 700;
}

.work-available h2 strong {
	position: relative;
	z-index: 1;
}

.work-available h2 strong::after {
	position: absolute;
	left: 0;
	bottom: 13px;
	content: "";
	height: 10px;
	width: 100%;
	background: var(--color-secondary);
	z-index: -1;
}

.shape-illustration {
	position: absolute;
	left: 0;
	max-width: 25%;
	bottom: -15%;
}

.dark-layout .work-available h2 strong::after {
	background: var(--color-primary);
	opacity: 0.5;
}

/* ============================================================== 
    12. Blog
=============================================================== */

.blog-style-one .thumb {
	overflow: hidden;
	border-radius: 8px;
	margin-bottom: 30px;
}

.blog-style-one h4 {
	line-height: 1.4;
	font-size: 22px !important;
}

.blog-style-one .meta li {
	display: inline-block;
	margin-right: 20px;
	text-transform: capitalize;
	font-weight: 400;
}

.blog-style-one .meta li i {
	margin-right: 5px;
	font-weight: 100;
	position: relative;
}

.blog-style-one .meta li a {
	font-weight: 400;
	color: var(--color-heading);
}

.blog-style-one .meta li a:hover {
	color: var(--color-primary);
}

.blog-style-one .thumb img {
	max-width: none;
	width: -webkit-calc(100% + 60px);
	width: calc(100% + 60px);
	-webkit-transition: opacity 0.35s,
		-webkit-transform 0.35s;
	transition: opacity 0.35s,
		transform 0.35s;
	-webkit-transform: translate3d(-50px, 0, 0);
	transform: translate3d(-50px, 0, 0);
}

.blog-style-one .item:hover .thumb img {
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}

.blog-style-one-area .item .info {
	border-radius: inherit;
	padding: 0;
}

.blog-style-one-area .meta {
	margin: 0;
}

.blog-style-one-area .item {
	box-shadow: inherit;
	border-radius: inherit;
	background: transparent;
}

.blog-style-one-area .blog-single-content .meta {
	margin-bottom: 15px;
}

.blog-style-one-area .blog-single-content .meta i {
	color: var(--color-primary);
}

.dark-layout .blog-style-one-area .blog-single-content .meta i {
	font-weight: 600;
}

.blog-style-one-area .blog-single-content .thumb {
	margin-bottom: 30px;
}

.blog-style-one-area .blog-single-content .thumb img {
	border-radius: inherit;
}

/* -------- Modal Blog -------- */

.modal-content {
	position: relative;
}

.modal-content .modal-header {
	position: absolute;
	border: none;
	margin: 0;
	padding: 0;
	width: auto;
	z-index: 9;
	right: 45px;
	top: 45px;
}

.modal-content .modal-header button.btn-close {
	height: 16px;
	width: 16px;
	line-height: 30px;
	background: var(--color-primary);
	opacity: 1;
	color: var(--white);
	border-radius: 50%;
	position: relative;
	font-weight: 500;
}

.modal-content .modal-header button.btn-close::after {
	position: absolute;
	left: 50%;
	top: 50%;
	content: "\eb99";
	font-family: "remixicon";
	font-size: 18px;
	transform: translate(-50%, -50%);
}

.modal-dialog-scrollable .modal-content {
	overflow: inherit;
	border-radius: inherit;
}

.modal-dialog-scrollable .modal-body {
	padding: 50px;
	border-radius: inherit;
}

.modal-dialog-scrollable .modal-body::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	background-color: #F5F5F5;
}

.modal-dialog-scrollable .modal-body::-webkit-scrollbar {
	width: 6px;
	background-color: #F5F5F5;
}

.modal-dialog-scrollable .modal-body::-webkit-scrollbar-thumb {
	background-color: var(--dark);
}

.dark-layout .modal-dialog-scrollable .modal-body::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px var(--white);
	background-color: #F5F5F5;
}

.dark-layout .modal-dialog-scrollable .modal-body::-webkit-scrollbar {
	width: 6px;
	background-color: #F5F5F5;
}

.dark-layout .modal-dialog-scrollable .modal-body::-webkit-scrollbar-thumb {
	background-color: var(--color-primary);
}

.modal-content .modal-header button.btn-close::before {
	position: absolute;
	left: -5px;
	top: -5px;
	content: "";
	height: 42px;
	width: 42px;
	background: var(--color-heading);
	z-index: -1;
	border-radius: 50%;
	opacity: 0.2;
}

.dark-layout .modal-content {
	background: var(--dark);
}

.dark-layout .blog-area blockquote {
	background: #012b45;
	font-size: 26px;
	line-height: 1.8;
	border: 1px solid rgba(255, 255, 255, 0.1);
	border-radius: 10px;
}

.dark-layout .blog-area blockquote::after {
	font-size: 50px;
	bottom: 30px;
	opacity: 0.07;
	right: 30px;
}

.dark-layout .post-author {
	border: 1px solid rgba(255, 255, 255, 0.1);
	border-radius: 10px;
	background: #012b45;
}

.dark-layout .post-pagination-area a .icon {
	border-color: rgba(255, 255, 255, 0.3);
	border-radius: 6px;
}

.dark-layout .post-pagination-area .nav-title {
	color: var(--color-primary);
}

.dark-layout .post-tags.share .tags a {
	background: #012b45;
	color: var(--white);
}

.dark-layout .post-tags.share .tags a:hover {
	background: var(--color-primary);
}

.dark-layout .blog-comments {
	border-color: rgba(255, 255, 255, 0.2);
}

.dark-layout .comments-area .comment-item {
	background: #012b45;
}

.dark-layout .comments-area .comment-item .content .reply a {
	background: var(--color-primary);
}

.dark-layout .comments-area .comments-form input,
.dark-layout .comments-area .comments-form textarea {
	border-color: rgba(255, 255, 255, 0.2);
	color: var(--white);
}

.dark-layout button.btn::after {
	background: var(--white);
}

.dark-layout button.btn:hover {
	color: var(--color-heading);
}

/* -------- Popup Style CSS -------- */
.white-popup {
	position: relative;
	background: #FFF;
	padding: 25px;
	width: auto;
	max-width: 400px;
	margin: 0 auto;
}

/* ============================================================== 
     13. Contact
=============================================================== */

.contact-info-list {
	display: flex;
}

.contact-info-list i {
	display: inline-block;
	height: 60px;
	width: 60px;
	line-height: 60px;
	text-align: center;
	background: var(--color-primary);
	color: var(--white);
	font-size: 30px;
	margin-right: 20px;
	min-width: 60px;
	margin-top: 5px;
	border-radius: 5px;
	font-weight: 100;
}

.contact-info-list p {
	margin-bottom: 5px;
	text-transform: uppercase;
}

.contact-info-list h5 {
	margin: 0;
	font-weight: 700;
	line-height: 1.3;
}

.contact-info-list {
	margin-top: 40px;
}

.contact-info-list:first-child {
	margin-top: 0;
}

.contact-info-list a {
	font-weight: 700;
}

.contact-info-list:first-child i {
	background: var(--color-secondary);
}

.contact-info-list:nth-child(3) i {
	background: #FEC447;
}

.contact-content .form-box {
	padding: 60px;
	background: rgba(255, 255, 255, 0.5);
}

.contact-content .form-box input,
.contact-content .form-box textarea {
	margin-bottom: 15px;
	background: transparent;
	padding: 15px;
	color: var(--color-heading);
	border: 1px solid rgba(255, 255, 255, 1);
	background: rgba(255, 255, 255, 0.5);
}

.contact-content .form-box textarea {
	min-height: 180px;
}

.contact-form-box p {
	margin-bottom: 30px;
}

.contact-content .form-box button {
	display: inline-block;
	font-weight: 800;
	text-transform: capitalize;
	transition: all 0.35s ease-in-out;
	overflow: hidden;
	border-radius: 3px;
	border: 2px solid transparent;
	-webkit-box-shadow: inherit;
	box-shadow: inherit;
	font-size: 16px;
	letter-spacing: 0;
	padding: 13px 30px;
	background: #E7EDF8;
	position: relative;
	z-index: 1;
	color: var(--white);
	border: none;
	background: var(--color-primary);
	padding: 16px 52px;
	font-size: 17px;
	border-radius: 30px;
	margin-top: 15px;
}

.contact-content .form-box button::after {
	position: absolute;
	top: inherit;
	right: inherit;
	bottom: inherit;
	left: -5%;
	content: "";
	height: 150px;
	width: 115%;
	z-index: -1;
	background-color: var(--dark);
	-webkit-transition: all 0.25s ease-in-out;
	transition: all 0.25s ease-in-out;
	-webkit-transform: translateY(-45%) skew(25deg) scale(0);
	transform: translateY(-45%) skew(25deg) scale(0);
}

.contact-content .form-box button:hover::after {
	-webkit-transform: translateY(-45%) skew(25deg) scale(1.2);
	transform: translateY(-45%) skew(25deg) scale(1.2);
}

.dark-layout .contact-content .form-box button::after {
	background: var(--color-secondary);
}

.text-shade {
	text-transform: uppercase;
	font-size: 200px;
	position: absolute;
	left: 30%;
	top: 150px;
	opacity: 0.04;
	z-index: -1;
}

img.loader {
	margin-left: 10px;
}

.dark-layout .contact-content .form-box {
	background: rgba(0, 0, 0, 0.2);
}

.dark-layout .contact-content .form-box input,
.dark-layout .contact-content .form-box textarea {
	color: var(--white);
	background:
		rgba(0, 0, 0, 0.2);
	border: 1px solid rgba(255, 255, 255, 0.2);
}

.dark-layout .contact-content .form-box input::-webkit-input-placeholder,
.dark-layout .contact-content .form-box textarea::-webkit-input-placeholder {
	color: var(--white);
	opacity: 0.8;
}

.dark-layout .contact-content .form-box input::-moz-placeholder,
.dark-layout .contact-content .form-box textarea::-moz-placeholder {
	color: var(--white);
	opacity: 0.8;
}

.dark-layout .contact-content .form-box input:-ms-input-placeholder,
.dark-layout .contact-content .form-box textarea:-ms-input-placeholder {
	color: var(--white);
	opacity: 0.8;
}

.dark-layout .contact-content .form-box input:-moz-placeholder,
.dark-layout .contact-content .form-box textarea:-moz-placeholder {
	color: var(--white);
	opacity: 0.8;
}

/* ============================================================== 
     14. Footer
=============================================================== */

.dark-layout footer {
	background: #003d63 !important;
}

.footer-social li {
	display: inline-block;
	margin-right: 30px;
	font-size: 24px;
}

.footer-social li {
	display: inline-block;
}

footer p {
	margin: 0;
	opacity: 0.8;
}

footer {
	padding: 50px 0;
}

/* ============================================================== 
     15. Not Found
=============================================================== */

.error-page-area{
height: 100vh;
align-items: center;
}

.error-box h1 {
	font-size: 160px;
	line-height: 110px;
	margin-bottom: 40px;
}

/* -------- ScrollUp -------- */

.customScrollUp {
	text-align: center;
	bottom: 40px;
	cursor: pointer;
	position: fixed;
	right: 20px;
	z-index: 999;
	height: 40px;
	width: 40px;
	border-radius: 50%;
	border: none;
	background: var(--color-primary);
	box-shadow: 0px 0px 2px rgb(0 0 0 / 40%);
	transition: 0.4s;
}

.customScrollUp i {
	font-size: 20px;
	color: #fff;
	line-height: 10px;
	position: relative;
	top: 6px;
}

.customScrollUp:hover {
	background-color: var(--color-secondary);
}

/* ============================================================== 
     # Responsive CSS
=============================================================== */

/* ----- Tablet Layout: 768px ----- */
@media only screen and (min-width: 768px) and (max-width: 991px) {

	/* ----- Default ----- */
	.box-layout {
		margin-left: 0.75rem;
		margin-right: 0.75rem;
	}

	.shape-right-top-mini {
		max-width: 60px;
		right: 0;
		top: -30px;
		display: none;
	}

	.heading-left {
		text-align: center;
	}

	.heading-left .fun-factor-default .fun-fact {
		float: none;
	}

	/* ----- Banner ----- */
	.banner-style-one {
		padding-top: 320px;
		position: relative;
		text-align: center;
	}

	.banner-style-one .text-invisible {
		left: 50%;
		transform: translateX(-50%);
		font-size: 120px;
		top: -70px;
	}

	/* ----- About ----- */
	.about-style-six .thumb::after {
		height: 100%;
		width: 100%;
	}

	.about-style-six {
		text-align: center;
	}

	.about-style-six .thumb {
		margin-bottom: 170px;
	}

	.skill-list li {
		display: block;
		margin-top: 20px;
		padding-top: 20px;
	}

	.skill-list li i {
		margin: 0;
		margin-bottom: 20px;
	}

	/* ----- Pricing ----- */
	.save-amount {
		margin-bottom: 60px;
	}

	/* ----- Services ----- */
	.service-style-one {
		border: none;
	}

	.service-style-one-item {
		padding: 50px 30px;
		background: rgba(255, 255, 255, 0.8);
	}

	.service-style-one-item img {
		margin-bottom: 30px;
	}

	.dark-layout .service-style-one-item {
		background: #012b45;
	}

	/* ----- Resume ----- */
	.experience-content-box .tab-content {
		padding: 0 50px;
	}

	ul.biography-table {
		display: block;
	}

	.skill-table i {
		margin-bottom: 20px;
		display: none;
	}

	.skill-table li {
		margin-top: 30px;
		padding: 0;
	}

	.skill-table .progress-box h5 {
		margin: 0;
		float: left;
		margin-right: 35px;
		font-size: 34px;
		font-weight: 800;
		position: relative;
		top: -30px;
	}

	.skill-table h4 {
		margin: 0;
		font-weight: 600;
		margin-left: 105px;
		font-size: 14px;
		color: var(--color-heading);
	}

	.dark-layout .skill-table h4 {
		color: var(--white);
	}

	/* ----- Contact ----- */
	.text-shade {
		display: none;
	}

	.contact-info-list {
		display: block;
		text-align: center;
	}

	.contact-info-list i {
		margin: 0;
		margin-bottom: 20px;
	}

	/* ----- Footer ----- */
	footer {
		padding: 30px 0;
		text-align: center;
	}

	footer .text-end {
		text-align: center !important;
		margin-top: 15px;
	}

	.footer-social li {
		margin: 0 15px;
	}
}

/* ----- Mobile Layout: 320px ----- */
@media only screen and (max-width: 767px) {

	/* ----- Default ----- */
	.box-layout {
		margin-left: 0.75rem;
		margin-right: 0.75rem;
	}

	.shape-right-top-mini {
		max-width: 60px;
		right: 0;
		top: -30px;
	}

	.blur-bg {
		display: none;
	}

	.heading-left {
		text-align: center;
	}

	.heading-shape {
		display: none;
	}

	/* ----- Banner ----- */
	.text-invisible {
		display: none;
	}

	.personal-social {
		display: none;
	}

	.banner-style-one h2 {
		font-size: 40px;
		margin-bottom: 20px;
	}

	.banner-style-one {
		padding-top: 230px;
		text-align: center;
	}

	.banner-style-one .button {
		margin-top: 40px;
	}

	/* ----- About ----- */
	.about-style-six .thumb::after {
		height: 100%;
		width: 100%;
	}

	.about-style-six {
		text-align: center;
	}

	.about-style-six .thumb {
		margin-bottom: 150px;
	}

	.skill-list li {
		display: block;
		margin-top: 20px;
		padding-top: 20px;
	}

	.skill-list li i {
		margin: 0;
		margin-bottom: 20px;
	}


	/* ----- Work Available ----- */
	.work-available h2 {
		font-size: 36px;
	}

	/* ----- Brand ----- */
	.brand-style-one {
		padding-bottom: 50px;
	}

	/* ----- Resume ----- */
	.resume-tab-navs {
		display: block;
		border-radius: inherit;
		background: transparent;
		padding: 0;
		box-shadow: inherit;
		margin-bottom: 40px;
	}

	.resume-tab-navs button {
		display: block;
		width: 100%;
		background: #ffffff !important;
		margin: 0;
		margin-top: 15px;
	}

	.resume-tab-navs button:first-child {
		margin: 0;
	}

	.experience-content-box::after {
		display: none;
	}

	ul.biography-table {
		display: block;
		padding: 30px;
		background: rgba(255, 255, 255, 0.7);
	}

	.dark-layout ul.biography-table {
		background: #012b45;
	}

	ul.biography-table li:first-child::after {
		display: none;
	}

	.skill-table i {
		margin-bottom: 20px;
		display: none;
	}

	.skill-table li {
		margin-top: 30px;
		padding: 0;
	}

	.skill-table .progress-box h5 {
		margin: 0;
		float: left;
		margin-right: 35px;
		font-size: 34px;
		font-weight: 800;
		position: relative;
		top: -30px;
	}

	.skill-table h4 {
		margin: 0;
		font-weight: 600;
		margin-left: 105px;
		font-size: 14px;
		color: var(--color-heading);
	}

	.education-table {
		display: block;
		margin-left: 10px;
	}

	.education-table li {
		margin-top: 30px;
	}

	.education-table li:first-child {
		margin-top: 0;
	}

	.experience-content-box {
		padding-bottom: 0;
	}

	.skill-table {
		padding: 30px;
		background: rgba(255, 255, 255, 0.8);
	}

	.dark-layout .resume-tab-navs {
		border: none;
		background: transparent;
	}

	.dark-layout .resume-tab-navs button {
		background: rgba(0, 0, 0, 0.5) !important;
	}

	.dark-layout .skill-table {
		background: #012b45;
		border: 1px solid rgba(255, 255, 255, 0.2);
	}

	.dark-layout .skill-table h4 {
		color: var(--white);
	}

	/* ----- Pricing ----- */
	.save-amount {
		margin-top: 40px;
		padding: 50px 30px;
		text-align: center;
		margin-bottom: 30px;
	}

	.save-amount img {
		display: none;
	}

	/* ----- Services ----- */
	.service-style-one-item {
		text-align: center;
		border-bottom: 1px solid rgba(80, 61, 205, 0.1);
		padding-bottom: 30px;
	}

	.service-style-one-item img {
		margin-bottom: 30px;
	}

	.service-style-one {
		border: none;
	}

	.service-style-one:last-child .service-style-one-item {
		border: none;
		padding-bottom: 0;
	}

	/* ----- Contact ----- */
	.text-shade {
		display: none;
	}

	/* ----- Portfolio ----- */
	.gallery-items .pf-item,
	.gallery-items.columns-3 .pf-item,
	.gallery-items.columns-4 .pf-item {
		width: 100%;
	}

	.magnific-mix-gallery {
		margin: 0;
	}

	.gallery-items .pf-item {
		padding-left: 0 !important;
		padding-right: 0 !important;
	}

	.shape-animated-right {
		max-width: 80px;
		top: -30px;
	}

	.fun-factor-default .fun-fact {
		margin-right: 30px;
		padding-right: 30px;
	}

	.fun-factor-default {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	/* ----- Project Details ----- */
	.project-details-items .top-info {
		margin-top: 0;
	}

	.project-info .project-basic-info {
		display: block;
	}

	.project-info .project-basic-info li {
		margin-top: 25px;
	}

	.project-info .project-basic-info li:first-child {
		margin-top: 0;
	}

	.project-info ul.social h4 {
		display: none;
	}

	.project-details-items .check-list {
		grid-template-columns: 1fr;
	}

	.dark-layout .contact-content .form-box {
		background: #012b45;
	}

	/* ----- Footer ----- */
	footer {
		padding: 30px 0;
		text-align: center;
	}

	footer .text-end {
		text-align: center !important;
		margin-top: 15px;
	}

	.footer-social li {
		margin: 0 15px;
	}

	/* ----- Error ----- */
	.error-box h1 {
		font-size: 140px;
	}
}

/* ----- Customized Width ----- */
@media only screen and (min-width: 700px) and (max-width: 1200px) {

	/* ----- Portfolio ----- */
	.gallery-items.columns-3 .pf-item,
	.gallery-items.columns-4 .pf-item {
		width: 50%;
	}

	.gallery-items .pf-item {
		padding: 15px !important;
	}

	.magnific-mix-gallery {
		margin: -15px !important;
	}
}

/* ----- Customized Width ----- */
@media only screen and (min-width: 992px) and (max-width: 1023px) {

	.about-style-six .thumb::after {
		height: 100%;
		width: 100%;
	}

	.shape-right-top-mini {
		right: 0;
	}

	.skill-list ul::after {
		height: 100%;
		width: 100%;
		left: 0;
		top: 0;
	}
}

/* ----- Customized Width ----- */
@media only screen and (min-width: 500px) and (max-width: 767px) {
	.skill-table {
		padding: 50px;
	}

	ul.biography-table {
		padding: 50px;
	}

}

@media only screen and (max-width: 575px) {
	.offcanvas-start {
		width: 300px;
	}
}

/* ----- Customized Width ----- */
@media only screen and (max-width: 400px) {

	.offcanvas-start {
		width: 220px;
	}

	ul.biography-table li {
		display: block;
	}

	ul.biography-table li h5 {
		margin-bottom: 3px;
	}

	.contact-content .form-box {
		padding: 30px;
	}

	.fun-factor-default {
		display: block;
	}

	.fun-factor-default .fun-fact {
		margin: 0;
		padding: 0;
		border: none;
		float: none;
		width: 100%;
		text-align: center;
		border-bottom: 1px solid;
		padding-bottom: 30px;
	}

	.fun-factor-default .counter {
		justify-content: center;
	}
}

@media (max-width: 500px) {

	.modal-dialog-scrollable .modal-body {
		padding: 50px 30px;
	}

	.modal-content .modal-header {
		right: 30px;
	}
}

@media (max-width: 1023px) {
	.modal-dialog {
		max-width: 90%;
		margin: 30px auto;
	}
}

/* -------- Preloader -------- */
.ambrox-preloader {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	cursor: default;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	height: 100%;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	z-index: 9000;
}

.ambrox-preloader .animation-preloader {
	z-index: 1000;
}

.ambrox-preloader .animation-preloader .spinner {
	-webkit-animation: spinner 1s infinite linear;
	animation: spinner 1s infinite linear;
	border-radius: 50%;
	border: 3px solid rgba(0, 0, 0, 0.2);
	border-top-color: var(--color-primary);
	height: 150px;
	margin: 0 auto 3.5em auto;
	width: 150px;
}

.torch-red .ambrox-preloader .animation-preloader .spinner {
	border-top-color: #ee2852;
}

.ambrox-preloader .animation-preloader .txt-loading {
	font: bold 5em "Poppins", sans-serif;
	text-align: center;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.ambrox-preloader .animation-preloader .txt-loading .letters-loading {
	color: rgba(0, 0, 0, 0.2);
	position: relative;
}

.ambrox-preloader .animation-preloader .txt-loading .letters-loading:before {
	-webkit-animation: letters-loading 4s infinite;
	animation: letters-loading 4s infinite;
	color: #000000;
	content: attr(data-text-preloader);
	left: 0;
	opacity: 0;
	font-family: "Poppins", sans-serif;
	position: absolute;
	top: -3px;
	-webkit-transform: rotateY(-90deg);
	transform: rotateY(-90deg);
}

.ambrox-preloader .animation-preloader .txt-loading .letters-loading:nth-child(2):before {
	-webkit-animation-delay: 0.2s;
	animation-delay: 0.2s;
}

.ambrox-preloader .animation-preloader .txt-loading .letters-loading:nth-child(3):before {
	-webkit-animation-delay: 0.4s;
	animation-delay: 0.4s;
}

.ambrox-preloader .animation-preloader .txt-loading .letters-loading:nth-child(4):before {
	-webkit-animation-delay: 0.6s;
	animation-delay: 0.6s;
}

.ambrox-preloader .animation-preloader .txt-loading .letters-loading:nth-child(5):before {
	-webkit-animation-delay: 0.8s;
	animation-delay: 0.8s;
}

.ambrox-preloader .animation-preloader .txt-loading .letters-loading:nth-child(6):before {
	-webkit-animation-delay: 1s;
	animation-delay: 1s;
}

.ambrox-preloader .animation-preloader .txt-loading .letters-loading:nth-child(7):before {
	-webkit-animation-delay: 1.2s;
	animation-delay: 1.2s;
}

.ambrox-preloader .animation-preloader .txt-loading .letters-loading:nth-child(8):before {
	-webkit-animation-delay: 1.4s;
	animation-delay: 1.4s;
}

.ambrox-preloader.dark .animation-preloader .spinner {
	border-color: rgba(255, 255, 255, 0.2);
	border-top-color: #fff;
}

.ambrox-preloader.dark .animation-preloader .txt-loading .letters-loading {
	color: rgba(255, 255, 255, 0.2);
}

.ambrox-preloader.dark .animation-preloader .txt-loading .letters-loading:before {
	color: #fff;
}

.ambrox-preloader p {
	font-size: 14px;
	font-weight: 600;
	text-transform: uppercase;
	letter-spacing: 8px;
	color: #3b3b3b;
}

.ambrox-preloader .loader {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	font-size: 0;
	z-index: 1;
	pointer-events: none;
}

.ambrox-preloader .loader .row {
	height: 100%;
}

.ambrox-preloader .loader .loader-section {
	padding: 0px;
}

.ambrox-preloader .loader .loader-section .bg {
	background-color: #ffffff;
	height: 100%;
	left: 0;
	width: 100%;
	-webkit-transition: all 800ms cubic-bezier(0.77, 0, 0.175, 1);
	-o-transition: all 800ms cubic-bezier(0.77, 0, 0.175, 1);
	transition: all 800ms cubic-bezier(0.77, 0, 0.175, 1);
}

.ambrox-preloader .loader.dark_bg .loader-section .bg {
	background: #111339;
}

.ambrox-preloader.loaded .animation-preloader {
	opacity: 0;
	-webkit-transition: 0.3s ease-out;
	-o-transition: 0.3s ease-out;
	transition: 0.3s ease-out;
}

.ambrox-preloader.loaded .loader-section .bg {
	width: 0;
	-webkit-transition: 0.7s 0.3s allcubic-bezier(0.1, 0.1, 0.1, 1);
	-o-transition: 0.7s 0.3s allcubic-bezier(0.1, 0.1, 0.1, 1);
	transition: 0.7s 0.3s allcubic-bezier(0.1, 0.1, 0.1, 1);
}

@-webkit-keyframes spinner {
	to {
		-webkit-transform: rotateZ(360deg);
		transform: rotateZ(360deg);
	}
}

@keyframes spinner {
	to {
		-webkit-transform: rotateZ(360deg);
		transform: rotateZ(360deg);
	}
}

@-webkit-keyframes letters-loading {

	0%,
	75%,
	100% {
		opacity: 0;
		-webkit-transform: rotateY(-90deg);
		transform: rotateY(-90deg);
	}

	25%,
	50% {
		opacity: 1;
		-webkit-transform: rotateY(0deg);
		transform: rotateY(0deg);
	}
}

@keyframes letters-loading {

	0%,
	75%,
	100% {
		opacity: 0;
		-webkit-transform: rotateY(-90deg);
		transform: rotateY(-90deg);
	}

	25%,
	50% {
		opacity: 1;
		-webkit-transform: rotateY(0deg);
		transform: rotateY(0deg);
	}
}

@media screen and (max-width: 767px) {
	.ambrox-preloader .animation-preloader .spinner {
		height: 8em;
		width: 8em;
	}

	.ambrox-preloader .animation-preloader .txt-loading {
		font: bold 3.5em "Poppins", sans-serif;
	}
}

@media screen and (max-width: 500px) {
	.ambrox-preloader .animation-preloader .spinner {
		height: 7em;
		width: 7em;
	}

	.ambrox-preloader .animation-preloader .txt-loading {
		font: bold 2em "Poppins", sans-serif;
	}

	nav.navbar.validnavs .navbar-collapse.collapse img {
		height: 40px;
		margin-top: 8px;
	}
}