/*
    Flaticon icon font: Flaticon
*/

  @font-face {
      font-family: "flaticon";
      src: url("../fonts/flaticon.eot");
      src: url("../fonts/flaticon.eot?#iefix") format("embedded-opentype"),
          url("../fonts/flaticon.woff") format("woff"),
          url("../fonts/flaticon.ttf") format("truetype"),
          url("../fonts/flaticon.svg#flaticon") format("svg");
      font-weight: normal;
      font-style: normal;
  }

  @media screen and (-webkit-min-device-pixel-ratio:0) {
      @font-face {
          font-family: "flaticon";
          src: url("../fonts/flaticon.svg#flaticon") format("svg");
      }
  }

  [class^="flaticon-"]:before,
  [class*=" flaticon-"]:before,
  [class^="flaticon-"]:after,
  [class*=" flaticon-"]:after {
      font-family: flaticon;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;

      /* Better Font Rendering =========== */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
  }

  .flaticon-start-up:before {
      content: "\f101";
  }

  .flaticon-startup:before {
      content: "\f102";
  }

  .flaticon-growth:before {
      content: "\f103";
  }

  .flaticon-startup-1:before {
      content: "\f104";
  }

  .flaticon-shuttle:before {
      content: "\f105";
  }

  .flaticon-rocket:before {
      content: "\f106";
  }

  .flaticon-rocket-1:before {
      content: "\f107";
  }

  .flaticon-company:before {
      content: "\f108";
  }

  .flaticon-development:before {
      content: "\f109";
  }

  .flaticon-startup-2:before {
      content: "\f10a";
  }

  .flaticon-idea:before {
      content: "\f10b";
  }

  .flaticon-entrepreneur:before {
      content: "\f10c";
  }

  .flaticon-startup-3:before {
      content: "\f10d";
  }

  .flaticon-solution:before {
      content: "\f10e";
  }

  .flaticon-startup-4:before {
      content: "\f10f";
  }

  .flaticon-idea-1:before {
      content: "\f110";
  }

  .flaticon-boost:before {
      content: "\f111";
  }

  .flaticon-startup-5:before {
      content: "\f112";
  }

  .flaticon-start-up-1:before {
      content: "\f113";
  }

  .flaticon-idea-2:before {
      content: "\f114";
  }

  .flaticon-presentation:before {
      content: "\f115";
  }

  .flaticon-startup-6:before {
      content: "\f116";
  }

  .flaticon-cloud:before {
      content: "\f117";
  }

  .flaticon-start-up-2:before {
      content: "\f118";
  }

  .flaticon-report:before {
      content: "\f119";
  }

  .flaticon-hand:before {
      content: "\f11a";
  }

  .flaticon-envelope:before {
      content: "\f11b";
  }

  .flaticon-graph:before {
      content: "\f11c";
  }

  .flaticon-paper:before {
      content: "\f11d";
  }

  .flaticon-project:before {
      content: "\f11e";
  }

  .flaticon-project-1:before {
      content: "\f11f";
  }

  .flaticon-briefing:before {
      content: "\f120";
  }

  .flaticon-management:before {
      content: "\f121";
  }

  .flaticon-management-1:before {
      content: "\f122";
  }

  .flaticon-complete:before {
      content: "\f123";
  }

  .flaticon-projects:before {
      content: "\f124";
  }

  .flaticon-project-management:before {
      content: "\f125";
  }

  .flaticon-concept:before {
      content: "\f126";
  }

  .flaticon-document:before {
      content: "\f127";
  }

  .flaticon-team:before {
      content: "\f128";
  }

  .flaticon-project-2:before {
      content: "\f129";
  }

  .flaticon-project-plan:before {
      content: "\f12a";
  }

  .flaticon-software:before {
      content: "\f12b";
  }

  .flaticon-layers:before {
      content: "\f12c";
  }

  .flaticon-coding:before {
      content: "\f12d";
  }

  .flaticon-software-engineer:before {
      content: "\f12e";
  }

  .flaticon-programmer:before {
      content: "\f12f";
  }

  .flaticon-hardware:before {
      content: "\f130";
  }

  .flaticon-web-development:before {
      content: "\f131";
  }

  .flaticon-cloud-service:before {
      content: "\f132";
  }

  .flaticon-license:before {
      content: "\f133";
  }

  .flaticon-computer:before {
      content: "\f134";
  }

  .flaticon-smartphone:before {
      content: "\f135";
  }

  .flaticon-cloud-data:before {
      content: "\f136";
  }

  .flaticon-biometrics:before {
      content: "\f137";
  }

  .flaticon-cloud-1:before {
      content: "\f138";
  }

  .flaticon-software-1:before {
      content: "\f139";
  }

  .flaticon-database:before {
      content: "\f13a";
  }

  .flaticon-3d-printing-software:before {
      content: "\f13b";
  }

  .flaticon-cloud-2:before {
      content: "\f13c";
  }

  .flaticon-software-2:before {
      content: "\f13d";
  }

  .flaticon-software-development:before {
      content: "\f13e";
  }

  .flaticon-cloud-computing:before {
      content: "\f13f";
  }

  .flaticon-cloud-computing-1:before {
      content: "\f140";
  }

  .flaticon-file:before {
      content: "\f141";
  }

  .flaticon-upload:before {
      content: "\f142";
  }

  .flaticon-upload-1:before {
      content: "\f143";
  }

  .flaticon-submit:before {
      content: "\f144";
  }

  .flaticon-cloud-upload:before {
      content: "\f145";
  }

  .flaticon-resume:before {
      content: "\f146";
  }

  .flaticon-research:before {
      content: "\f147";
  }

  .flaticon-risk:before {
      content: "\f148";
  }

  .flaticon-secure:before {
      content: "\f149";
  }

  .flaticon-bullhorn:before {
      content: "\f14a";
  }

  .flaticon-risk-1:before {
      content: "\f14b";
  }

  .flaticon-manager:before {
      content: "\f14c";
  }

  .flaticon-data-processing:before {
      content: "\f14d";
  }

  .flaticon-collaboration:before {
      content: "\f14e";
  }

  .flaticon-operational-risk:before {
      content: "\f14f";
  }

  .flaticon-collaboration-1:before {
      content: "\f150";
  }

  .flaticon-management-2:before {
      content: "\f151";
  }

  .flaticon-money-management:before {
      content: "\f152";
  }

  .flaticon-online-business:before {
      content: "\f153";
  }

  .flaticon-project-management-1:before {
      content: "\f154";
  }

  .flaticon-data-management:before {
      content: "\f155";
  }

  .flaticon-product-management:before {
      content: "\f156";
  }

  .flaticon-data-management-1:before {
      content: "\f157";
  }

  .flaticon-planning:before {
      content: "\f158";
  }

  .flaticon-marketing-agent:before {
      content: "\f159";
  }

  .flaticon-testing:before {
      content: "\f15a";
  }

  .flaticon-conversation:before {
      content: "\f15b";
  }

  .flaticon-simulation:before {
      content: "\f15c";
  }

  .flaticon-antivirus:before {
      content: "\f15d";
  }

  .flaticon-marketing-strategy:before {
      content: "\f15e";
  }

  .flaticon-cyber-security:before {
      content: "\f15f";
  }

  .flaticon-3d-printing:before {
      content: "\f160";
  }

  .flaticon-cds:before {
      content: "\f161";
  }

  .flaticon-support:before {
      content: "\f162";
  }

  .flaticon-technical-support:before {
      content: "\f163";
  }

  .flaticon-support-1:before {
      content: "\f164";
  }

  .flaticon-customer-service:before {
      content: "\f165";
  }

  .flaticon-customer-support:before {
      content: "\f166";
  }

  .flaticon-technical-support-1:before {
      content: "\f167";
  }

  .flaticon-online-support:before {
      content: "\f168";
  }

  .flaticon-cloud-service-1:before {
      content: "\f169";
  }

  .flaticon-customer-service-1:before {
      content: "\f16a";
  }

  .flaticon-data-analysis:before {
      content: "\f16b";
  }

  .flaticon-analysis:before {
      content: "\f16c";
  }

  .flaticon-monitoring:before {
      content: "\f16d";
  }

  .flaticon-report-1:before {
      content: "\f16e";
  }

  .flaticon-market-analysis:before {
      content: "\f16f";
  }

  .flaticon-qualitative-research:before {
      content: "\f170";
  }

  .flaticon-analysis-1:before {
      content: "\f171";
  }

  .flaticon-analysis-2:before {
      content: "\f172";
  }

  .flaticon-data-analysis-1:before {
      content: "\f173";
  }

  .flaticon-dashboard:before {
      content: "\f174";
  }

  .flaticon-monitoring-1:before {
      content: "\f175";
  }

  .flaticon-startup-7:before {
      content: "\f176";
  }

  .flaticon-badge:before {
      content: "\f177";
  }

  .flaticon-high-quality:before {
      content: "\f178";
  }

  .flaticon-premium-quality:before {
      content: "\f179";
  }

  .flaticon-award:before {
      content: "\f17a";
  }

  .flaticon-diamond:before {
      content: "\f17b";
  }

  .flaticon-diamond-2:before {
      content: "\f17c";
  }

  .flaticon-research-1:before {
      content: "\f17d";
  }

  .flaticon-web-analysis:before {
      content: "\f17e";
  }

  .flaticon-monitoring-2:before {
      content: "\f17f";
  }

  .flaticon-shield:before {
      content: "\f180";
  }

  .flaticon-security:before {
      content: "\f181";
  }

  .flaticon-secure-1:before {
      content: "\f182";
  }

  .flaticon-secure-payment:before {
      content: "\f183";
  }

  .flaticon-update:before {
      content: "\f184";
  }

  .flaticon-update-1:before {
      content: "\f185";
  }

  .flaticon-synchronization:before {
      content: "\f186";
  }

  .flaticon-data-integration:before {
      content: "\f187";
  }

  .flaticon-update-2:before {
      content: "\f188";
  }

  .flaticon-technology:before {
      content: "\f189";
  }